import React from 'react'
import styled from '@emotion/styled'


import HealthBackground from '../../assets/images/base/ExerciseStatistics@2x.png'
import ShopBackground from '../../assets/images/base/Well-BeingShop@2x.png'
import HealthTipsBackground from '../../assets/images/base/HealthTips@2x.png'
import HealthProfileBackground from '../../assets/images/base/HealthProfile.jpg'
import { healthProfile, healthTips } from '../../constant/path'
import { useHistory } from 'react-router'


const MenuLayout = styled.div`
min-height: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: row;
  > div {
    display: flex; 
    flex-basis: calc(50% - 10px);  
    justify-content: center;
    flex-direction: column;

  }

  > div > div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    font-size: 32px;
    font-weight: 600;
  }

  .box { 
    margin: 5px;
    height: 150px;
    cursor: pointer;
   }

.box1 { 
  cursor: pointer;
  background-repeat: round !important;
  background: url(${HealthBackground});
}
.box2 { 
  cursor: pointer;
  background-repeat: round !important;
  background: url(${ShopBackground});
}
.box3 { 
  cursor: pointer;
  background-repeat: round !important;
  background: url(${HealthTipsBackground});
}
.box4 { 
  cursor: pointer;
  background-repeat: round !important;
  background: url(${HealthProfileBackground});
}
`


const Menu = () => {
  const history = useHistory()

  return (
    <MenuLayout>
      {/* <div className="box box1"><div>Exercise Statistics</div></div> */}
      {/* <div className="box box2"><div>Shop</div></div> */}

      <div className="box box3" onClick={() => history.push(healthTips())}>
        <div>
          Health Tips
        </div>
      </div>

      {/* <div className="box box4" onClick={() => history.push(healthProfile())}><div>Health Profile</div></div> */}
    </MenuLayout>
  )
}

export default Menu