import Text from '../../components/common/Text'
import styled from '@emotion/styled'
import { mobile, useScreen, mobileVerySmall } from '../../utils/responsive-helper'
import Image from '../../components/common/Image'
import EClaimListComponent from './EClaimListComponent'
import { EClaimCard } from './component'
import Button from '../../components/common/Button'
import Modal from '../../components/common/Modal'
import color from '../../constant/colors'
import { useHistory } from 'react-router'
import * as path from '../../constant/path'
import { useGetUser } from '../../services/user/user-query'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useState } from 'react'
import mainImage from '../../assets/images/base/mainModal.png'


const Layout = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  row-gap: 32px;
  padding: 24px 16px 0px;
`
const HistoryButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;

  ${mobile} {
    margin: 32px 0px 0px;
  }
`
const FamilyDetailButton = styled(Button)`
  width: fit-content;
  align-self: flex-end;
`

const ContentModal = styled.div`
  padding: 60px 0px 0px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
`

const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
`

const BenefitSelectButton = styled(Button)`
  width: min-content;
  border-color: ${color.PRIMARY};
  width: 100%;
  max-width: 100%;
`

const Eclaim = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { data, isLoading, isError } = useGetUser()
  const [modal, setModal] = useState(false)

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>โปรดลองใหม่อีกครั้ง...</div>
  }

  return (
    <>
      <Modal
        visible={modal}
        hideCloseIcon
        onCancelText={t('eClaim.history.title')}
        onCancel={() => setModal(true)}
        footerComponent={
          <FooterModal>
            <BenefitSelectButton onClick={() => history.push(path.eClaimHistory())}>ดูประวัติการเบิก  </BenefitSelectButton>
          </FooterModal>
        }
      >
        <ContentModal>
          <Image src={mainImage} />
          <Text size={18} type="Bold">
            ไม่อยู่ในช่วงเวลาที่ให้บริการ
          </Text>
          <Text size={14} style={{
            textAlign: 'center'
          }}>
            ระบบจะเปิดให้ส่งเรื่องเบิกสวัสดิการอีกครั้ง <br />
            ในวันที่ 1 มกราคม 2567 ขออภัยในความไม่สะดวก
          </Text>

        </ContentModal>
      </Modal>
      <Layout>
        <HistoryButton
          fontSize={16}
          background={color.WHITE}
          fontColor={color.BLACK}
          onClick={() => history.push(path.eClaimHistory())}
        >
          {t('eClaim.history.title')}
        </HistoryButton>
        <EClaimCard
          title={t('eClaim.employeeBenefits')}
          icon='IconPersonalWhite'
          date={`${t('eClaim.claimLastDate')} ${dayjs(`11-25-${language === 'th' ? '2567' : '2024'}`).locale(language).format('DD MMMM YYYY')}`}
          balance={data?.point || 0}
        />
        <FamilyDetailButton
          fontSize={16}
          background={color.WHITE}
          fontColor={color.BLACK}
          onClick={() => history.push(path.relative())}
        >
          {t('eClaim.familyButton')}
        </FamilyDetailButton>
        <TitleContainer>
          <Image imageName='IconEClaimList' />
          <Text type="Bold" size={16}>
            {t('eClaim.titleEclainmList')}
          </Text>
        </TitleContainer>
        <EClaimListComponent />
      </Layout>
    </>
  )
}

export default Eclaim
