import { api } from '../../utils/api'
import {
  GetNewsProps,
  HilightNewsProps,
  NewsCategoryType,
  NewsType,
  NewsWithPaging,
  GetRecommendNews,
  SentQuizAnswerProps,
  VoteProps,
} from './digital-news-types'
import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  InfiniteQueryObserverOptions,
  UseMutationOptions,
} from 'react-query'
import {
  CreateCommentParams,
  CommentType,
  MockCommentWithPaging,
  CreateCommentResponse,
  GetNewsDetailProps,
  ReadNewsDetailProps,
  GetNewsDetailResponse,
  NewsDetailResponse,
} from './digital-news-types'

import {
  TeamOutlined,
  FileOutlined,
  ReadOutlined,
  SoundOutlined,
  SyncOutlined,
  CommentOutlined,
} from '@ant-design/icons'

import IconAll from '../../assets/images/icnAll.png'
import { ContentType } from '../../utils/api/tools'
import Image from '../../components/common/Image'
import { queryCache } from '../..'
import { GET_USER_COIN_URL } from '../user/user-query'
import { COIN_TRANSACTION_URL } from '../point-history/point-history-query'

export const BASE_DIGITAL_NEWS_URL = '/api/digital_news'

const NEWS_LIST_URL = `${BASE_DIGITAL_NEWS_URL}/list`
const GET_NEWS_DETAIL_URL = `${BASE_DIGITAL_NEWS_URL}/get`
const GET_NEWS_CATEGORY = `${BASE_DIGITAL_NEWS_URL}_type/list_news_type`
const READ_NEWS_DETAIL = `${BASE_DIGITAL_NEWS_URL}/user_action/inform`
// const SENT_QUIZ_ANSWER = `${BASE_DIGITAL_NEWS_URL}/user_action/quiz`
const VOTE_URL = '/api/admin/digital_news/choice_vote/vote'
const SENT_QUIZ_ANSWER = '/api/admin/digital_news/quiz_items/select_items'
const BASE_COMMENT_URL = `${BASE_DIGITAL_NEWS_URL}/comment`
const GET_HILIGHT_NEW_URL = `${BASE_DIGITAL_NEWS_URL}/list_highlight`
const GET_COMMENT_LIST_URL = `${BASE_COMMENT_URL}/list`
const GET_COMMENT_COUNT_URL = `${BASE_COMMENT_URL}/numberComment`
const CREATE_COMMENT_URL = `${BASE_COMMENT_URL}/create`

const GET_RECOMMEND_NEWS_URL = `/api/admin/digital_news/list_recomment_news`

export const useGetNews = (params: GetNewsProps) => {
  const { size, userId, category, subCategory } = params
  return useInfiniteQuery<NewsWithPaging>(
    [NEWS_LIST_URL, { category }],
    async ({ pageParam = 0 }) => {
      const response = await api.belive.get<Array<NewsType>>(NEWS_LIST_URL, {
        page: pageParam,
        size: size,
        userId: userId,
        digitalNewsType: category,
        digitalNewsSubType: subCategory,
      })
      const responseWithPaging: NewsWithPaging = {
        data: response,
        paging: {
          page: pageParam,
          nextPage: pageParam + 1,
        },
      }
      return responseWithPaging
    },
    {
      getNextPageParam: (lastGroup: NewsWithPaging) => {
        const { nextPage } = lastGroup.paging
        return lastGroup.data.length < size ? null : nextPage
      },
    },
  )
}

export const useGetRecommendNews = () => {
  return useQuery([GET_RECOMMEND_NEWS_URL], async () => {
    const response = await api.belive.get<GetRecommendNews>(GET_RECOMMEND_NEWS_URL, {})
    return response
  })
}

export const useGetNewsDetail = (props: GetNewsDetailProps) => {
  const { id, userId } = props
  return useQuery(
    [GET_NEWS_DETAIL_URL, id, userId],
    async () => {
      const response = await api.belive.get<GetNewsDetailResponse>(GET_NEWS_DETAIL_URL, {
        id,
        userId,
      })
      return Promise.resolve(response)
    },
    {
      enabled: !!userId,
    },
  )
}

const onNewsDetailActionSuccess = () => {
  queryCache.invalidateQueries(GET_NEWS_DETAIL_URL)
  queryCache.invalidateQueries(GET_USER_COIN_URL)
  queryCache.invalidateQueries(COIN_TRANSACTION_URL)
}

export const useReadNewsDetail = (option?: any) => {
  const { onSuccess } = option || {}
  const mutate = useMutation(
    async (data: ReadNewsDetailProps) => {
      return api.belive.post<NewsDetailResponse>(READ_NEWS_DETAIL, data)
    },
    {
      onSuccess: (data, variables, context) => {
        onNewsDetailActionSuccess()
        onSuccess && onSuccess()
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
      ...option,
    },
  )
  return mutate
}

export const useSentQuizAnswer = (option?: any) => {
  const { onSuccess } = option || {}
  const mutate = useMutation(
    async (data: SentQuizAnswerProps) => {
      return api.belive.post<NewsDetailResponse>(SENT_QUIZ_ANSWER, data, { contentType: ContentType.JSON })
    },
    {
      onSuccess: (data, variables, context) => {
        onNewsDetailActionSuccess()
        onSuccess && onSuccess()
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
      ...option,
    },
  )
  return mutate
}

export const useVote = (option?: any) => {
  const { onSuccess } = option || {}
  const mutate = useMutation(
    async (data: VoteProps) => {
      return api.belive.post<NewsDetailResponse>(VOTE_URL, data, { contentType: ContentType.JSON })
    },
    {
      onSuccess: (data, variables, context) => {
        onNewsDetailActionSuccess()
        onSuccess && onSuccess()
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
      ...option,
    },
  )
  return mutate
}

export const useGetCommentCount = (newsId: number = 0) => {
  return useQuery(
    [GET_COMMENT_COUNT_URL, newsId],
    async () => {
      const response = await api.belive.get<number>(GET_COMMENT_COUNT_URL, { newsId })
      return response
    },
    {
      enabled: !!newsId,
    },
  )
}

export const useGetCommentList = (
  newsId: number,
  config?: InfiniteQueryObserverOptions<MockCommentWithPaging>,
) => {
  const pageSize = 10
  return useInfiniteQuery<MockCommentWithPaging>(
    [GET_COMMENT_LIST_URL, { newsId }],
    async ({ pageParam }) => {
      const response = await api.belive.get<CommentType[]>(GET_COMMENT_LIST_URL, {
        page: pageParam || 0,
        size: pageSize,
        newsId: newsId,
      })
      const responseWithPaging: MockCommentWithPaging = {
        data: response,
        paging: {
          page: pageParam || 0,
          nextPage: (pageParam || 0) + 1,
        },
      }
      return responseWithPaging
    },
    {
      getNextPageParam: (lastGroup: MockCommentWithPaging) => {
        const { data, paging } = lastGroup
        const { nextPage } = paging
        return data.length >= pageSize ? nextPage : null
      },
      keepPreviousData: true,
      ...config,
    },
  )
}

export const useCreateNewComment = (
  config?: UseMutationOptions<CreateCommentResponse, unknown, CreateCommentParams>,
) => {
  return useMutation<CreateCommentResponse, unknown, CreateCommentParams>(
    (data) => {
      return api.belive.post<CreateCommentResponse>(CREATE_COMMENT_URL, data, {
        contentType: ContentType.FORMDATA,
      })
    },
    {
      ...config,
    },
  )
}

export const useGetHilightNews = (params: HilightNewsProps) => {
  const { userId } = params
  return useQuery(GET_HILIGHT_NEW_URL, () =>
    api.belive.get<Array<NewsType>>(GET_HILIGHT_NEW_URL, {
      page: 0,
      size: 7,
      userId: userId,
    }),
  )
}

export const useGetNewsType = () => {
  return useQuery(GET_NEWS_CATEGORY, async () => {
    const response = await api.belive.get<Array<NewsCategoryType>>(GET_NEWS_CATEGORY)
    const all: NewsCategoryType = {
      id: 0,
      name: 'All',
      icon: <Image imageName="iconAll" style={{ width: 40, height: 40, marginRight: 10 }} />,
    }
    response.unshift(all) // add element to first item of array
    return response
  })
}
