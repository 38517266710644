import { useCallback, useMemo } from 'react'
import CategoryShopGroup from '../../components/common/CategoryShopGroup'
import ShopCard from '../../components/ShopCard'
import { useGetShopProductList } from '../../services/shop/shop-query'
import * as paths from '../../constant/path'

type ProductListType = {
  title: string
  categoryId: number
}

const ProductList = (props: ProductListType) => {
  const { title: categoryName, categoryId } = props
  const { data } = useGetShopProductList(categoryId)

  const getPath = useCallback(
    (productId: number) => {
      return paths.categoryReward({
        routeParam: { rewardId: productId, categoryId },
        queryParam: { title: categoryName },
      })
    },
    [categoryId, categoryName],
  )

  const viewMorePath = useMemo(() => {
    return paths.shopCategory({ routeParam: { categoryId }, queryParam: { title: categoryName } })
  }, [categoryId, categoryName])

  const isShowContent = useMemo(() => {
    return data?.content.length !== 0
  }, [data])

  const content = useMemo(() => {
    return data?.content.map((content, index: number) => {
      const { id, specialCoin, normalCoin, title, thumbnailImage, remain } = content
      return (
        <ShopCard
          key={index}
          image={thumbnailImage}
          title={title}
          point={specialCoin !== null ? specialCoin : normalCoin}
          remain={remain}
          path={getPath(id)}
        />
      )
    })
  }, [data])

  return isShowContent ? (
    <CategoryShopGroup
      label={categoryName}
      path={viewMorePath}
      contentStyle={{ marginLeft: -5, marginBottom: 40 }}
    >
      {content}
    </CategoryShopGroup>
  ) : null
}

export default ProductList
