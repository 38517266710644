import styled from '@emotion/styled'
import Image from '../../components/common/Image'
import Card from '../../components/common/Card'
import CategoryGroup from '../../components/common/CategoryShopGroup'
import { useGetShopBrand } from '../../services/shop/shop-query'
import * as paths from '../../constant/path'
import Loading from '../../components/common/Loading'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

const StyledImage = styled(Image)`
  border-radius: 6px;
  cursor: pointer;
  object-fit: contain;
  background-color: white;
`

const Brand = () => {
  const { data, isLoading } = useGetShopBrand()
  const getPath = useCallback((brandId: number, title: string) => {
    return paths.shopBrand({ routeParam: { brandId }, queryParam: { title } })
  }, [])

  const content = useMemo(() => {
    return data?.content.map((content: any, index: number) => {
      const { id, imageUrl, name } = content
      return (
        <Link to={getPath(id, name)} key={index}>
          <Card>
            <StyledImage src={imageUrl} title={name} alt={name} width={121} height={121} />
          </Card>
        </Link>
      )
    })
  }, [data])

  return (
    <CategoryGroup label="">
      <Loading loading={isLoading}>{content}</Loading>
    </CategoryGroup>
  )
}

export default Brand
