import styled from '@emotion/styled'
import { CSSProperties, ReactNode } from 'react'
import React from 'react'
import { RightOutlined } from '@ant-design/icons'
import color from '../../constant/colors'
import Loading from './Loading'
import Text from './Text'
import { hideScrollStyle, mobile } from '../../utils/responsive-helper'
import { Link } from 'react-router-dom'

const Layout = styled.div``
const HeaderLayout = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  
  ${mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`
const TextStyle = styled(Text)`
  color: ${color.BLACK};
`
const ViewMoreLayout = styled(Link)`
  display: flex;
  flex-flow: row;
  align-items: center;
`
const ViewMoreStyle = styled(Text)`
  color: ${color.BLACK};
`

const ContentLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 8px;
  overflow-y: hidden;
  overflow-x: scroll;
  > *:not(:first-of-type) {
    margin-left: 24px;
  }
  > {
    background-color: red;
  }
  ${hideScrollStyle}

  ${mobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`

export type CategoryShopGroup = {
  label: string
  children?: ReactNode
  style?: CSSProperties
  contentStyle?: CSSProperties
  path?: string
  viewMoreText?: string
  loading?: boolean
} & React.HTMLAttributes<HTMLDivElement>

const CategoryShopGroup = (props: CategoryShopGroup) => {
  const { label, children, loading = false, path, viewMoreText, contentStyle, ...restProps } = props

  return (
    <Layout {...restProps}>
      <HeaderLayout>
        <TextStyle size={24} type="Bold">
          {label}
        </TextStyle>
        {path && (
          <ViewMoreLayout to={path} style={{ cursor: loading ? 'default' : 'pointer' }}>
            <ViewMoreStyle size={16} type="Medium">
              {viewMoreText || 'View More'}
            </ViewMoreStyle>
            <RightOutlined style={{ marginLeft: 4, color: color.PRIMARY }} />
          </ViewMoreLayout>
        )}
      </HeaderLayout>
      <Loading loading={loading}>
        <ContentLayout style={contentStyle}>{children}</ContentLayout>
      </Loading>
    </Layout>
  )
}

export default CategoryShopGroup