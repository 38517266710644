import { CSSProperties } from 'react'
import styled from '@emotion/styled'
import Card from './common/Card'
import Text from './common/Text'
import Image from './common/Image'
import TagRemain from './common/TagRemain'
import icon from '../assets/images/group-16.svg'
import { useTranslation } from 'react-i18next'
import { mobile } from '../utils/responsive-helper'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'

const CustomStyleCard = styled(Card)`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 267px;
  height: 328px;
  margin-left: 0px;

  :hover {
    cursor: pointer;
  }

  .tag-remain {
    position: absolute;
    margin-left: 7px;
    margin-top: 7px;
    height: 19px;
    z-index: 1000;
  }

  ${mobile} {
    .tag-remain {
      margin-left: 9px;
      margin-top: 9px;
      height: 24px;
    }
  }
`
const CustomStyleImage = styled(Image)`
  width: 267px;
  height: 189px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const TitleContainer = styled.div`
  margin: 16px 16px 8px 16px;
  display: block;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const CustomStyleFooter = styled.div`
  display: flex;
  flex-direction: row;
  height: 32px;
  margin-left: 16px;
  margin-bottom: 20px;
`

const CustomStyleScoreText = styled(Text)`
  margin-left: 5px;
  padding-top: 3.5px;
`

const FlexChild = styled.div`
  flex: 1;
`

type ShopCardProps = {
  path: string
  image: string
  title: string
  point: number
  remain: number | string
  style?: CSSProperties
}

const ShopCard = (props: ShopCardProps) => {
  const { t } = useTranslation()
  const { image, title, point, remain, style, path, ...restProps } = props
  const pointLabel = point || 0

  return (
    <Link to={path}>
      <CustomStyleCard className="shop-card" style={style} {...restProps}>
        <CustomStyleImage className="shop-card-img" src={image} />
        <TagRemain remain={remain} />
        <Tooltip title={title}>
          <TitleContainer>
            <Text ellipsis maxLine={1} size={20} type={'Medium'}>
              {title}
            </Text>
          </TitleContainer>
        </Tooltip>
        <FlexChild />
        <CustomStyleFooter>
          <Image imageName="iconCoinWithBackground" />
          &nbsp;&nbsp;
          <Text ellipsis maxLine={1} size={20} type={'Bold'}>
            {pointLabel.toLocaleString(undefined, { maximumFractionDigits: 2 })}
          </Text>
          <CustomStyleScoreText ellipsis maxLine={1} size={16} type={'Text'}>
            {t('app.point')}
          </CustomStyleScoreText>
        </CustomStyleFooter>
      </CustomStyleCard>
    </Link>
  )
}

export default ShopCard
