import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import color from '../constant/colors'
import Button from './common/Button'
import { mobile, useScreen } from '../utils/responsive-helper'
import Text from './common/Text'
import Image from './common/Image'
import { CameraOutlined, CloseCircleOutlined } from '@ant-design/icons'
import {
  useGetCommentCount,
  useGetCommentList,
  useCreateNewComment,
} from '../services/digital-news/digital-news-query'
import { reduxForm, InjectedFormProps } from 'redux-form'
import { InputField } from './common/fields'
import dayjs from 'dayjs'
import { animateScroll as scroll } from 'react-scroll'
import {
  CommentParam,
  CommentType,
  CreateCommentParams,
} from '../services/digital-news/digital-news-types'
import { useTranslation } from 'react-i18next'
import Avatar from './common/Avatar'
import { keyframes } from '@emotion/react'
import config from '../config'
const fade = keyframes`
  0% {
    opacity: 0;    
  }

  25% {
    opacity: 0.5;    
  }

  50% {
    opacity: 1;    
  }

  100% {
    opacity: 0.5;    
  }
`
const CommentLayout = styled.div`
  border: 0px solid #000000;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
`
const HeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${color.BORDER};
`
const HeaderText = styled(Text)`
  width: max-content;
  white-space: nowrap;
`
const HeaderLine = styled.div`
  height: 4px;
  border-width: 2px;
  border-color: ${color.PRIMARY};
  width: 100%;
  border-style: solid;
  margin-left: 10px;
`
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 19px;
  margin-bottom: 16px;
`
const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${color.BORDER};
`
const CommentSectionLayout = styled.div`
  background-color: ${color.BACKGROUND_LIGHT_GRAY};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  align-items: flex-start;
`
const AvatarContainer = styled.div`
  border: 0px solid black;
  width: 22px;
  height: 100%;
`
const FooterComment = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .user-name,
  .comment-at {
    line-height: 30px;
  }
  .comment-at {
    height: 100%;
    white-space: nowrap;
    align-self: flex-start;
  }
`
const NameContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`
const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const ImageContainer = styled.div`
  border: 0px solid black;
  display: flex;
  height: 167px;
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
`
const CommentImage = styled.img`
  border: 0px solid black;
  height: 167px;
  width: 167px;
  object-fit: contain;

  ${mobile} {
    object-fit: cover;
  }
`
const InputWithImageUpload = styled.div`
  height: 72px;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: center;
  input {
    padding-right: 44px;
  }
`
const ImageUploadButton = styled.label`
  border: 0px solid #000000;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 0px;
  height: 30px;
  z-index: 2;
  margin-top: 11px;
  cursor: pointer;
`
const InputFileHidden = styled.input`
  display: none;
`
const ImagePreviewPanel = styled.div<{ isMobile: boolean }>`
  background-color: ${color.WHITE};
  border-top-width: 0px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${color.LIGHT_GRAY};
  padding: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-right: ${({ isMobile }) => (isMobile ? 0 : 73)}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ScrollPanel = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  /* flex: 1; */
  /* height: 100%; */
  /* overflow-y: scroll; */
`
const SubmitButton = styled(Button)`
  width: 50%;
  height: 46px;
  align-self: flex-end;
`
const LoadingDiv = styled.div`
  padding: 10px 5px;

  > div {
    animation: ${fade} 1.25s ease-in-out infinite;
  }
`
const CommentEmptyText = styled(Text)`
  margin: 40px 0;
  text-align: center;
`
type LanguageType = {
  language: string
}
type CommentSectionType = CommentType & LanguageType
const CommentSection = (props: CommentSectionType) => {
  const { id, user, createDate, comment, imagePath, language } = props
  return (
    <CommentSectionLayout key={id}>
      <ContentContainer>
        <Text size={14} color={color.FONT_BLACK}>
          {comment}
        </Text>
        {imagePath ? (
          <ImageContainer>
            <CommentImage src={`${config.apiHost}${imagePath}`} alt="" />
          </ImageContainer>
        ) : null}
      </ContentContainer>
      <FooterComment>
        <NameContainer>
          <AvatarContainer>
            <Avatar size={22} src={`${config.apiHost}${user?.profileImagePath}`} />
          </AvatarContainer>
          <Text className="user-name" size={12} type="Medium" color={color.PRIMARY}>
            {user?.firstName} {user?.lastName}
          </Text>
        </NameContainer>
        <Text className="comment-at" size={10} color={color.FONT_LIGHT_GRAY} style={{ alignSelf: 'end' }}>
          {dayjs(createDate).format(`DD/MM/YY HH:mm ${language === 'th' ? 'น.' : ''}`)}
        </Text>
      </FooterComment>
    </CommentSectionLayout>
  )
}

const FORM_NAME = 'COMMENT_FORM'
const required = (value: string) => (value ? undefined : ' ')
type CommentFormProps = CommentParam & Partial<InjectedFormProps<CommentParam>>

const Comment = (props: CommentFormProps) => {
  const { handleSubmit, reset, userId, digitalNewsId, isInputComment } = props

  const { t, i18n } = useTranslation()
  const { isMobile } = useScreen()
  const [selectedFile, setSelectedFile] = useState<CreateCommentParams['image']>('')
  const [preview, setPreview] = useState('')

  const { data: totalCommentCount, refetch: refetchCommentCount } =
    useGetCommentCount(digitalNewsId)

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetched,
    refetch: refetchCommentList,
  } = useGetCommentList(digitalNewsId)

  const { mutate: updateComment } = useCreateNewComment()

  const onSelectFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile('')
      return
    }
    setSelectedFile(e.target.files[0])
  }, [])

  const onRemoveClick = useCallback((e) => {
    e.preventDefault()
    setPreview('')
    setSelectedFile('')
  }, [])

  const resetComment = useCallback(() => {
    reset && reset()
    setPreview('')
    setSelectedFile('')
  }, [reset])

  const onSubmitComment = useCallback(
    (form) => {
      updateComment(
        {
          userId: userId,
          digitalNewsId: digitalNewsId,
          comment: form.comment,
          image: selectedFile,
        },
        {
          onSuccess: (data) => {
            scroll.scrollToTop({ containerId: 'scrollPanel', offset: 100, isDynamic: true })
            refetchCommentCount()
            refetchCommentList()
            resetComment()
          },
        },
      )
    },
    [
      updateComment,
      userId,
      digitalNewsId,
      selectedFile,
      resetComment,
      refetchCommentCount,
      refetchCommentList,
    ],
  )

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const { scrollHeight, scrollTop, clientHeight } = e.currentTarget
      const bottom = scrollHeight - scrollTop >= clientHeight - 50
      if (bottom && isFetched && hasNextPage) {
        fetchNextPage()
      }
    },
    [fetchNextPage, isFetched, hasNextPage],
  )

  useEffect(() => {
    if (!selectedFile) {
      setPreview('')
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const renderComments = useMemo(
    () => data?.pages.map(({ data: comments }) => comments.map(item => <CommentSection {...item} language={i18n.language} />)),
    [data],
  )

  return (
    <CommentLayout>
      <HeaderContainer>
        <Image imageName="iconComment" width={24} height={24} />
        <Text size={14} color={color.FONT_LIGHT_GRAY}>
          {totalCommentCount || 0} {t('comment.commentCount')}
        </Text>
      </HeaderContainer>
      <CommentContainer>
        <ScrollPanel id="scrollPanel" onScroll={handleScroll}>
          {renderComments}
          {totalCommentCount === 0 && !isLoading && (
            <CommentEmptyText>{t('comment.commentEmpty')}</CommentEmptyText>
          )}
          {!isLoading && isFetchingNextPage ? (
            <LoadingDiv>
              <Text>{t('comment.loading')}</Text>
            </LoadingDiv>
          ) : null}
        </ScrollPanel>
      </CommentContainer>
      {isInputComment && (
        <>
          <InputContainer>
            <InputWithImageUpload>
              <InputField
                name="comment"
                validate={[required]}
                placeholder={isMobile ? t('comment.placeholder') : t('comment.placeholderFull')}
                style={{ width: '100%' }}
              />
              {/* <ImageUploadButton htmlFor="upload-btn">
            <CameraOutlined style={{ fontSize: 25 }} />
          </ImageUploadButton>
          <InputFileHidden type="file" accept="image/*" id="upload-btn" onChange={onSelectFile} /> */}
            </InputWithImageUpload>
          </InputContainer>
          <SubmitButton onClick={handleSubmit && handleSubmit(onSubmitComment)}>
            {t('comment.submitButton')}
          </SubmitButton>
        </>
      )}
      {selectedFile && (
        <ImagePreviewPanel isMobile={isMobile}>
          <CommentImage src={preview} alt="" />
          <CloseCircleOutlined style={{ fontSize: 25 }} onClick={onRemoveClick} />
        </ImagePreviewPanel>
      )}
    </CommentLayout>
  )
}

export default reduxForm<{}, CommentFormProps>({
  form: FORM_NAME,
})(Comment)
