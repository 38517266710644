import { useMutation, useQuery, useQueryClient } from 'react-query'
import { api } from '../../utils/api'
import { ContentType } from '../../utils/api/tools'
import {
  CreateEclaimUser,
  EClaimEnllomentItemList,
  EClaimItem,
  EClaimList,
  GetEClaimByIdProps,
  UploadFile,
  UploadReceiptImageResponse,
  CreateUserClaimEnrollments,
  GetRelativeResponse,
  EClaimListResponse,
  ECliamHistoryListResponse,
  GetEClaimHistoryByIdProps,
  RelativeScgResponse,
} from './e-claim-types'
import { useTranslation } from 'react-i18next'

const APP = '/app'
export const USER_CLAIM = 'userClaim'
export const E_CLAIM_LIST = `${APP}/${USER_CLAIM}/getClaimList`
export const E_CLAIM_BY_ID = `${APP}/${USER_CLAIM}/getById`

export const E_CLAIM_LIST_SCG = `${APP}/${USER_CLAIM}/getAllUserClaimByUser`

export const E_CLAIM_HISTORY_BY_ID = `${APP}/${USER_CLAIM}/getUserClaimById`

export const CREATE_USER_CLAIM = `${APP}/${USER_CLAIM}/createUserClaim`
export const UPLOADFILE = `${APP}/${USER_CLAIM}/upload/file`
export const E_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`
export const CREATE_USER_CLAIM_ENROLLMENT = `${APP}/${USER_CLAIM}/enrollment`
export const GET_RELATIVE = `${APP}/enrollments/relative`
export const GET_RELATIVE_SCG = `${APP}/enrollments/relative-list`
export const GET_GROUP_NUMBER = `${APP}/enrollments/self-benefits/group`
export const UPLOAD_RECEIPT_IMAGE = `${APP}/${USER_CLAIM}/eclaim/upload_receipt_image`
export const CANCEL_ECLAIM = `${APP}/${USER_CLAIM}/cancel`

export const useGetGroupNumber = () => {
  return useQuery(GET_GROUP_NUMBER, async () => {
    return await api.belive.get<any>(GET_GROUP_NUMBER)
  })
}

export const useGetEClaimList = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimList>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListScg = () => {
  return useQuery([E_CLAIM_LIST], () => {
    return api.belive.get<EClaimListResponse>(`${E_CLAIM_LIST}`)
  })
}

export const useGetEClaimListHistoryScg = () => {
  return useQuery([E_CLAIM_LIST_SCG], () => {
    return api.belive.get<ECliamHistoryListResponse>(`${E_CLAIM_LIST_SCG}`)
  })
}

export const useGetEClaimById = (props: GetEClaimByIdProps) => {
  const { claimId } = props

  return useQuery([E_CLAIM_BY_ID, claimId], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_BY_ID}`, { id: claimId })
  })
}

export const useGetEClaimHistoryById = (props: GetEClaimHistoryByIdProps) => {
  const { id } = props

  return useQuery([E_CLAIM_HISTORY_BY_ID, id], () => {
    return api.belive.get<EClaimItem>(`${E_CLAIM_HISTORY_BY_ID}`, { id: id })
  })
}
export const useCreateUserClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateEclaimUser) => {
      const {
        id,
        claimId,
        claimName,
        exchangeCoin = 0,
        userClaimPrice = 0,
        relativeId,
        receiptFile,
        optionalFileUrl,
        userRemark = '',
      } = data
      return api.belive.post(
        CREATE_USER_CLAIM,
        JSON.stringify({
          id: id,
          claimId: claimId,
          exchangeCoin: exchangeCoin,
          claimName: claimName,
          receiptFile: receiptFile,
          optionalFileUrl: optionalFileUrl,
          userClaimPrice: userClaimPrice,
          relativeId: relativeId,
          userRemark: userRemark,
        }),
        { contentType: ContentType.JSON },
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
        queryClient.invalidateQueries([E_CLAIM_LIST_SCG])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}
export const useUploadFile = () => {
  const mutate = useMutation(
    async (file: File) => {
      return api.belive.post<UploadFile>(
        UPLOADFILE,
        { file },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useUploadReceiptImage = () => {
  const mutate = useMutation(
    async (file: File) => {
      return api.belive.post<UploadReceiptImageResponse>(
        `${UPLOAD_RECEIPT_IMAGE}`,
        { image: file },
        { contentType: ContentType.FORMDATA },
      )
    },
    {
      onSuccess: (data) => {
        console.log('Upload File Success', data)
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
  return mutate
}

export const useGetEClaimEnrollment = (id: number | string) => {
  return useQuery([E_CLAIM_ENROLLMENT, id], () => {
    return api.belive.get<EClaimEnllomentItemList>(`${E_CLAIM_ENROLLMENT}`, { id: id })
  })
}

export const useCreateUserClaimEnrollment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: CreateUserClaimEnrollments) => {
      return api.belive.post(CREATE_USER_CLAIM_ENROLLMENT, data, { contentType: ContentType.JSON })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_ENROLLMENT])
        queryClient.invalidateQueries([E_CLAIM_LIST])
        queryClient.invalidateQueries([E_CLAIM_BY_ID])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}

export const useGetRelative = () => {
  return useQuery([GET_RELATIVE], () => {
    return api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
  })
}

export const useGetRelativeOptions = () => {
  return useQuery([GET_RELATIVE, 'options'], async () => {
    const data = await api.belive.get<GetRelativeResponse>(`${GET_RELATIVE}`)
    const optionRelative: {
      label: string
      value: string | number
    }[] = []
    optionRelative.push({ label: 'ตัวเอง', value: '0' })

    data?.map((relativesGroup) => {
      return relativesGroup.relativeList.map((relative) => {
        const { firstname, lastname, relativeTypeId } = relative
        const label = `${firstname} ${lastname}`
        optionRelative.push({ label: `${firstname} ${lastname}`, value: relativeTypeId })
      })
    })
    return optionRelative
  })
}

export const useGetRelativeOptionScg = (relativeTypeId?: number) => {
  const { t } = useTranslation()

  return useQuery([GET_RELATIVE_SCG, 'options', relativeTypeId], async () => {
    const data = await api.belive.get<RelativeScgResponse>(`${GET_RELATIVE_SCG}`)
    const optionRelative: {
      label: string
      value: string | number
      disabled?: boolean
    }[] = []
    optionRelative.push({ label: t('eClaim.detail.form.pleaseSelect'), value: '0', disabled: true })

    if (relativeTypeId) {
      data.filter(items => items.typeId === relativeTypeId).map((relative) => {
        const { firstname, lastname, id, typeName } = relative
        optionRelative.push({ label: `${typeName}: ${firstname} ${lastname}`, value: id })
      })
    } else {
      data.map((relative) => {
        const { firstname, lastname, id, typeName } = relative
        optionRelative.push({ label: `${typeName}: ${firstname} ${lastname}`, value: id })
      })
    }

    return optionRelative
  })
}

export const useCancelEClaim = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      return api.belive.post(`${CANCEL_ECLAIM}/${id}`, { contentType: ContentType.JSON })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([E_CLAIM_LIST_SCG])
        queryClient.invalidateQueries([E_CLAIM_HISTORY_BY_ID])
      },
      onError: (e: any) => {
        if (e.message) {
          console.log(e.message)
        }
      },
    },
  )
}