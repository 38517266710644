import React from 'react'
import styled from '@emotion/styled'
import AzureAuthenticationContext from '../../services/authAzure/azure-authentication-context'
import { AccountInfo } from '@azure/msal-browser'
import Button from '../../components/common/Button'
import LogoImage from '../../assets/images/base/logo-with-name.svg'

const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const isIE = msie > 0 || msie11 > 0

const Logo = styled.img`
  width: calc(100% - 22px);
`

// Log In, Log Out button
const LoginAD = ({ onSubmit }: any): JSX.Element => {
  // Azure client context
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext()
  const [authenticated, setAuthenticated] = React.useState<Boolean>(false)
  const [user, setUser] = React.useState<AccountInfo>()

  // Azure Login
  const logIn = (method: string): any => {
    const typeName = 'loginPopup'
    const logInType = isIE ? 'loginRedirect' : typeName
    authenticationModule.login(logInType, returnedAccountInfo)
  }

  // Azure Logout
  const logOut = (): any => {
    if (user) {
      onSubmit(undefined)
      authenticationModule.logout(user)
    }
  }

  const returnedAccountInfo = (user: AccountInfo, username: string, token: string) => {
    setAuthenticated(user?.name ? true : false)
    onSubmit(token, username)
  }

  return (
    <div id="authentication">
      {authenticationModule.isAuthenticationConfigured ? (
        <div>
          {/* <Logo src={LogoImage} /> */}
          <Button
            id="authenticationButton"
            style={{ marginTop: 30, width: 310 }}
            onClick={() => logIn('loginPopup')}
          >
            Login with Microsoft 365
          </Button>
        </div>
      ) : (
        <div>Authentication Client ID is not configured.</div>
      )}
    </div>
  )
}

export default LoginAD
