import styled from '@emotion/styled'
import Card from './common/Card'
import Image from './common/Image'
import Text from './common/Text'
import { Tooltip } from 'antd'
import { mobile, mobileVerySmall, useScreen } from '../utils/responsive-helper'
import color from '../constant/colors'
const LayoutCard = styled(Card)`
  height: 250px;
  ${mobile} {
    height: 100%;
    min-height: 180px;
    width: 100%;
    margin: 2px;
  }
  ${mobileVerySmall} {
    height: 100%;
    width: 100%;
    margin: 8px;
  }
`
const LayoutFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
`

const LayoutRowImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 27px;
`

const LayoutRowTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 8px;
  text-align: center;
`

const TextEllipsis = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  ${mobile} {
    -webkit-line-clamp: 6;
  }
  ${mobileVerySmall} {
    -webkit-line-clamp: 3;
  }
`

type CustomCardProps = {
  height?: number
  icon: string
  title: string
  maxClaimablePrice?: number
  availableAmount?: number
  isTax?: boolean
  onClick?: () => void
}

const WellBeingCardScg = (props: CustomCardProps) => {
  const { onClick, icon, title, isTax } = props

  const { isMobile } = useScreen()
  const fontSize = !isMobile ? 20 : 16
  return (
    <LayoutCard className="well-being-card" onClick={onClick}>
      <LayoutFlexColumn>
        <LayoutBody>
          <LayoutRowImage>
            {icon ? (
              <Image src={icon} width={48} height={48} />
            ) : (
              <Image imageName="iconNews" width={48} height={48} />
            )}
          </LayoutRowImage>
          <Tooltip title={title}>
            <LayoutRowTitle>
              {/* {title.split(' ').map((text, index) => {
                return (
                  <TextEllipsis key={index} size={fontSize} type={'Bold'} color={isTax ? color.PRIMARY : color.PINK}>
                    {text}
                  </TextEllipsis>
                )
              })} */}
              <TextEllipsis size={fontSize} type={'Bold'} color={isTax ? color.PRIMARY : color.PINK}>
                {title}
              </TextEllipsis>
            </LayoutRowTitle>
          </Tooltip>
        </LayoutBody>
      </LayoutFlexColumn>
    </LayoutCard>
  )
}

export default WellBeingCardScg
