import React from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useGetUserCoin } from '../../services/user/user-query'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import Text from '../../components/common/Text'
import Image from '../../components/common/Image'
import color from '../../constant/colors'
import dayjs from 'dayjs'

const PointLayout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .top {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
`
const PointCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  background: ${color.WHITE};
  padding: 4px 8px;
  border-radius: 8px;
`

const ExpireDate = styled(Text)`
  align-self: flex-end;
`

type PointProps = {
  textColor?: string
}
const Point = (props: PointProps) => {
  const { textColor } = props
  const { t, i18n } = useTranslation()
  const { data: userCoin } = useGetUserCoin()
  return (
    <PointLayout>
      <div className='top'>
        <Text color={textColor ? textColor : ''}>{t('app.coinNow')}</Text>
        <PointCard>
          <Text size={18} type="SemiBold">{userCoin?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</Text>
          <Image imageName='iconPoints' />
        </PointCard>
      </div>
      <ExpireDate size={12} color={textColor ? textColor : ''}>{t('app.expiredOn')} {dayjs('12-31-2566').locale(i18n.language).format('DD MMM YYYY')}</ExpireDate>
    </PointLayout>
  )
}

export default Point