import { CSSProperties, ReactNode, useMemo } from 'react'
import Image, { ImageNameKeys } from '../../components/common/Image'
import { mobile, tablet, mobileVerySmall, useScreen } from '../../utils/responsive-helper'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Text from '../../components/common/Text'
import { Collapse } from 'antd'
import { EClaimHistory, EClaimStatus } from '../../services/e-claim/e-claim-types'
import { useTranslation } from 'react-i18next'

const EClaimCardContainer = styled.div`
  background: ${color.WHITE};
  border: 0.5px solid ${color.BORDER};
  box-shadow: 0px 4px 4px rgba(218, 218, 218, 0.25);
  border-radius: 8px;
  padding: 16px;
`
const EClaimCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`
const EClaimCardProfileLayout = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`
const EClaimCardProfileTextLayout = styled.div`
  display: flex;
  flex-direction: column;
`
const EClaimCardProfileTextBoxLayout = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
const EClaimCardRightTextLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 8px;
  max-width: 200px;
`
const ImageIcon = styled(Image)`
  width: 40px;
  height: 40px;
  background: ${color.PRIMARY};
  padding: 8px;
  border-radius: 4px;
    ${mobile} {
    width: 20px;
    height: 25px;
  }
    ${mobileVerySmall} {
    width: 40px;
    height: 40px;
  }
`
const CustomCollapse = styled(Collapse)`
  background-color: ${color.WHITE};
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 8px;
  }
`
const EClaimStatusContainer = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 8px;
  width: max-content;
`
const EClaimTextMoney = styled(Text)`
  width: max-content;
`

type EClaimCardProps = {
  title: string
  icon: ImageNameKeys
  onClick?: () => void
  date: string
  balance: number
}
export const EClaimCard = (props: EClaimCardProps) => {
  const { title, icon, onClick, date, balance } = props
  const { t } = useTranslation()

  const userCoinFormated = useMemo(() => {
    return balance.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }, [balance])

  return (
    <EClaimCardContainer
      onClick={onClick}
    >
      <EClaimCardWrapper>
        <EClaimCardProfileLayout>
          <ImageIcon imageName={icon} />
          <EClaimCardProfileTextLayout>
            <Text size={14} type='Bold' color={color.PRIMARY}>{title}</Text>
            <Text size={10} type='Bold' color={color.BLACK}>{date}</Text>
          </EClaimCardProfileTextLayout>
        </EClaimCardProfileLayout>
        <EClaimCardRightTextLayout>
          <EClaimTextMoney size={14} type='Bold' color={color.BLACK}>{t('app.eligibleBudget')}</EClaimTextMoney>
          <Text size={18} type='Bold' color={color.PRIMARY}>{userCoinFormated} ฿</Text>
        </EClaimCardRightTextLayout>
      </EClaimCardWrapper>
    </EClaimCardContainer>
  )
}

type EClaimCardLayoutProps = {
  data: EClaimHistory
}
export const EClaimHistoryCard = (props: EClaimCardLayoutProps) => {
  const { data } = props
  const { t } = useTranslation()

  const statusText = useMemo(() => {
    let statusText = ''
    switch (data?.status) {
      case EClaimStatus.PROCESSING:
        statusText = t('eClaim.history.status.pending')
        break;
      case EClaimStatus.APPROVED:
        statusText = t('eClaim.history.status.waitDocument')
        break;
      case EClaimStatus.REJECTED:
        statusText = t('eClaim.history.status.rejected')
        break;
      case EClaimStatus.USER_CANCELLED:
        statusText = t('eClaim.history.status.cancel')
        break;
      case EClaimStatus.PAYMENT_CONFIRMED:
        statusText = t('eClaim.history.status.receiveDocument')
        break;
      default: statusText = t('eClaim.history.status.pending')
        break;
    }

    return statusText
  }, [data?.status])
  const statusColor = useMemo(() => {
    return data?.accept === null ? color.PENDING : data?.accept ? color.APPROVE : color.REJECT
  }, [data?.accept])
  const backgroundColor = useMemo(() => {
    return data?.accept === null ? color.PENDING_LIGHT : data?.accept ? color.APPROVE_LIGHT : color.REJECT_LIGHT
  }, [data?.accept])

  const exchangeCoin = useMemo(() => {
    return data.exchangeCoin.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }, [data.exchangeCoin])

  return (
    <EClaimCardContainer>
      <EClaimCardWrapper>
        <EClaimCardProfileLayout>
          <ImageIcon src='' />
          <EClaimCardProfileTextBoxLayout>
            {/* <Text size={14} type='Bold' color={color.PRIMARY}>{data}</Text> */}
            <Text size={18} type='Bold' color={color.PRIMARY}>{data.claimName}</Text>
          </EClaimCardProfileTextBoxLayout>
        </EClaimCardProfileLayout>
        <EClaimCardRightTextLayout>
          <EClaimStatusContainer backgroundColor={backgroundColor}>
            <Text size={14} type='Bold' color={statusColor}>{statusText}</Text>
          </EClaimStatusContainer>
          <Text size={18} type='Bold' color={color.PRIMARY}>-{exchangeCoin} ฿</Text>
        </EClaimCardRightTextLayout>
      </EClaimCardWrapper>
    </EClaimCardContainer>
  )
}

type CollapeProps = {
  children: ReactNode
  style?: CSSProperties
}
export const CollapseWellbeing = (props: CollapeProps) => {
  const { children, style } = props
  return (
    <CustomCollapse expandIconPosition={'right'} style={style}>
      {children}
    </CustomCollapse>
  )
}
