import React from 'react'
import styled from '@emotion/styled'
import PlayCardList from '../../components/play/PlayCardList'
import MyPlayCardList from '../../components/play/MyPlayCardList'
import { mobile } from '../../utils/responsive-helper'
import Text from '../../components/common/Text'
import { useGetUser } from '../../services/user/user-query'
import { Tabs } from 'antd'
import color from '../../constant/colors'
import { useTranslation } from 'react-i18next'
import Point from '../../components/common/Point'

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  > * {
    margin-top: 24px;
  }
  .profile-card {
    margin-right: 24px;
    ${mobile} {
      margin-right: unset;
      margin-bottom: 16px;
    }
  }
`

const BackgroundHeader = styled.div`
  background: linear-gradient(180deg, #DDDDDD 0%, #F4F4F4 100%);
  width: 100%;
  height: 266px;
  margin-top: 0px !important;
`

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  position: absolute;
  padding: 60px;
  margin: 0 auto;

  .left {
    display: flex;
    flex-direction: column;
  }
`
const TabsLayout = styled.div`
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 600;
    color: ${color.PRIMARY};
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 600;
    color: ${color.PRIMARY};
  }
 
  .ant-tabs-nav {
    margin-bottom: 24px;
    margin: 0 60px;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin: 0;
  }

  .ant-tabs-ink-bar {
    background: ${color.PRIMARY} !important;
  }
`

const Play = () => {
  const { data: user } = useGetUser()
  const { t } = useTranslation()
  return (
    <Layout>
      <FlexRow>
        <div className='left'>
          <Text size={32} type="Bold">{`${t('play.title')} ${user?.firstName} ${user?.lastName}`}</Text>
          <Text size={14} type="Bold">{t('play.subtitle')}</Text>
        </div>
        <Point />
      </FlexRow>
      <BackgroundHeader />
      <div>

        <TabsLayout>
          <Tabs>
            <Tabs.TabPane tab={t('play.events')} key="item-1">
              <PlayCardList />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('play.myevent')} key="item-2">
              <MyPlayCardList />
            </Tabs.TabPane>
          </Tabs>
        </TabsLayout>
      </div>
    </Layout>
  )
}

export default Play
