import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import CategoryGroup from '../../components/common/CategoryGroup'
import Text from '../../components/common/Text'
import Image from '../../components/common/Image'
import Button from '../../components/common/Button'
import color from '../../constant/colors'
import { useCallback } from 'react'
import { useHistory } from 'react-router'
import MaskImage from '../../assets/images/base/mask@2x.jpg'
import iconNews from '../../assets/images/base/icon-news.png'
import { useGetEnrollmentPeriod } from '../../services/enrollment/enrollment-query'
import { IconHealth, IconNew, IconPlay, IconInsurance, IconCheckup } from '../../assets/images/images-scg'
import { tablet } from '../../utils/responsive-helper'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  border: 0.5px solid #DDDDDD;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom:16px;
  user-select: none;
  :hover {
    cursor: pointer;
  }

  ${tablet} {
    width: 100%;
  }
`
const CustomImage = styled(Image)`
  width: 22px;
  height: 22px;
`
const AddAppIconLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 83px;
  height: 83px;
  border-radius: 20px;
  border: solid 2px ${color.ADD_APP_ICON};
`

type ItemProps = {
  src: string
  label: string
  url: string
  type?: string
  description: string
}

const Item = (props: ItemProps) => {
  const { src, label, url, type, description, ...rest } = props
  const history = useHistory()
  const onClickItem = useCallback(() => {
    history.push(url)
  }, [history, url])
  return (
    <>
      <Layout {...rest}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          rowGap: 8,
        }} onClick={onClickItem}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 8,
          }}>
            <CustomImage src={src || MaskImage} />
            <Text size={14} type="Bold" style={{ textAlign: 'center', marginTop: 8, whiteSpace: 'nowrap' }}>
              {label}
            </Text>
          </div>
          <Text size={10}>{description}</Text>
          {/* <Text size={14} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
            {labelEn}
          </Text> */}
        </div>
      </Layout>
    </>
  )
}

const MyApp = () => {
  const { t } = useTranslation()
  const { data: period } = useGetEnrollmentPeriod()
  const { benefitYearId } = period || {}

  return (
    <>
      <CategoryGroup label={t('home.category.myApp.title')} style={{ marginBottom: 16 }}>
        <Item
          key={1}
          src={IconPlay}
          label={t('home.category.myApp.play.title')}
          url={`/play`}
          type={'0'}
          description={t('home.category.myApp.play.description')}
        />
        <Item
          key={1}
          src={IconNew}
          label={t('home.category.myApp.news.title')}
          url={'/news'}
          type={'0'}
          description={t('home.category.myApp.news.description')}
        />
        <Item
          key={1}
          src={IconInsurance}
          label={t('home.category.myApp.insurance.title')}
          url={`/enrollment`}
          type={'0'}
          description={t('home.category.myApp.insurance.description')}
        />
        <Item
          key={1}
          src={IconCheckup}
          label={t('home.category.myApp.healthCheckup.title')}
          url={`/health-checkup`}
          type={'0'}
          description={t('home.category.myApp.healthCheckup.description')}
        />
        <Item
          key={1}
          src={IconHealth}
          label={t('home.category.myApp.wellBeing.title')}
          url={`/well-being`}
          type={'0'}
          description={t('home.category.myApp.wellBeing.description')}
        />
        {/* <Item
          key={1}
          src={CalendarIcon}
          label={'E - Claim'}
          url={'/enrollment'}
          type={'0'}
          description="E - Claim"
        />
        <Item
          key={1}
          src={CalendarIcon}
          label={'Marketplace'}
          url={'/shop'}
          type={'0'}
          description="Marketplace"
        /> */}
        {/* <Item
          key={1}
          src={CalendarIcon}
          label={'Transfer Point'}
          url={'/shop'}
          type={'0'}
          description="แลกรับสิทธิประโยชน์"
        /> */}
      </CategoryGroup>
      {/* <Button background={color.WHITE} style={{ borderRadius: 8, width: '100%', maxWidth: 'none' }}>
        <Text size={14} type="SemiBold" style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>ปรับแต่งโมดูล</Text>
      </Button> */}
    </>
  )
}

export default MyApp
