import React, { useCallback } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import styled from '@emotion/styled'
import Loading from '../../components/common/Loading'
import { eClaimDetail } from '../../constant/path'
import { useScreen } from '../../utils/responsive-helper'
import { useRouter } from '../../utils/helper'
import { useGetEClaimHistoryById, useCancelEClaim } from '../../services/e-claim/e-claim-query'
import EClaimInfo from './EClaimInfo'
import EClaimForm from '../EClaimDetail/EClaimForm'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import moment from 'moment'
import Headbar from '../../components/common/HeadBar'
import * as paths from '../../constant/path'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { setClaimInfo } from '../../redux-store/e-claim-reducer'

const BreadcrumbContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

const EClaimHistoryDetail = () => {
  const { query } = useRouter<{ id: number }>()
  const history = useHistory()
  const dispatch = useDispatch()
  const { mutate: cancelEClaim } = useCancelEClaim()
  const { data: eClaimItem, isLoading } = useGetEClaimHistoryById({ id: query.id })
  const {
    exchangeCoin,
    userClaimPrice,
    userRemark,
    optionalFileUrl,
    receiptFile,
    relativeId,
    accept,
    id,
    claimName = '',
    status
  } = eClaimItem || {}

  const initialValue = useMemo(() => {
    return {
      id,
      exchangeCoin,
      userClaimPrice,
      userRemark: userRemark,
      optionalFileUrl: optionalFileUrl,
      relativeTypeId: relativeId || '0',
      receiptFile: receiptFile,
      status,
      claimName
    }
  }, [id, optionalFileUrl, exchangeCoin, userClaimPrice, receiptFile, relativeId, status, userRemark])
  const disable = useMemo(() => {
    if (!accept && (accept !== null)) {
      return false
    } else {
      return true
    }
  }, [accept])

  const onCreateNewEClaim = useCallback(async () => {
    if (eClaimItem?.id === undefined) return
    dispatch(
      setClaimInfo({
        name: claimName,
        exchangeCoin: exchangeCoin || 0,
        userClaimPrice: userClaimPrice || 0,
        optionalFileUrl: optionalFileUrl || '',
        receiptFile: receiptFile || '',
        userRemark: userRemark || '',
        relativeId: relativeId || '0',
      }),
    )

    cancelEClaim(eClaimItem?.id, {
      onSuccess: () => {
        history.push(eClaimDetail({
          routeParam: {
            eClaimId: eClaimItem?.claimId
          },
          queryParam: {
            claimTypeId: eClaimItem?.claimTypeId.toString(),
            isCreateNew: 'true'
          }
        }))
      }
    })
  }, [eClaimItem, history, cancelEClaim, claimName, exchangeCoin, userClaimPrice, optionalFileUrl, receiptFile, userRemark, relativeId, dispatch])

  return (
    <Loading loading={isLoading}>
      <Headbar title={claimName} backWard={paths.eClaimHistory()} />
      <EClaimInfo id={query.id} eClaimItem={eClaimItem} onCreateNewEClaim={onCreateNewEClaim} />
      <EClaimForm
        eClaimItem={eClaimItem}
        initialValues={initialValue}
        allpayStatus={disable}
        isHistory={true}
        editable={false}
      />
    </Loading>
  )
}

export default EClaimHistoryDetail
