import styled from '@emotion/styled'
import ComponentImageSlider from '../../components/common/ImageShopSlider'
import { useGetShopImageSlider } from '../../services/shop/shop-query'

const ImageSlider = () => {
  const { data } = useGetShopImageSlider()
  return <ComponentImageSlider autoplay images={data?.content} />
}

export default ImageSlider
