import { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import Text from '../../components/common/Text'
import { EClaimItem, EClaimStatus } from '../../services/e-claim/e-claim-types'
import { useCancelEClaim } from '../../services/e-claim/e-claim-query'
import color from '../../constant/colors'
import dayjs from 'dayjs'
import { Divider } from 'antd'
import Button from '../../components/common/Button'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import { useTranslation } from 'react-i18next'
import { mobile } from '../../utils/responsive-helper'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: ${color.WHITE};
  padding: 16px;
  border-radius: 8px;
`
const EClaimInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const EClaimInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`
const EClaimInfoItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`
const EClaimStatusLayout = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 8px;
  width: fit-content;
`
const GroupButtonContainer = styled.div`
  display: flex;
  column-gap: 16px;

  ${mobile} {
    flex-direction: column;
    row-gap: 16px;
  
  }
`
const ButtonStyle = styled(Button)`
  ${mobile} {
    width: 100%;
    max-width: 100%;
  }
`

type EClaimInfoProps = {
  id: number
  eClaimItem?: EClaimItem
  onCreateNewEClaim: () => void
}
const EClaimInfo = (prop: EClaimInfoProps) => {
  const { id, eClaimItem, onCreateNewEClaim } = prop
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const { language } = i18n
  const { mutate: cancelEClaim } = useCancelEClaim()

  const date = useMemo(() => {
    return dayjs(eClaimItem?.createdDate).locale(language).format('DD MMM YYYY')
  }, [eClaimItem?.createdDate])

  const statusText = useMemo(() => {
    let statusText = ''
    switch (eClaimItem?.status) {
      case EClaimStatus.PROCESSING:
        statusText = t('eClaim.history.status.pending')
        break;
      case EClaimStatus.APPROVED:
        statusText = t('eClaim.history.status.waitDocument')
        break;
      case EClaimStatus.REJECTED:
        statusText = t('eClaim.history.status.rejected')
        break;
      case EClaimStatus.USER_CANCELLED:
        statusText = t('eClaim.history.status.cancel')
        break;
      case EClaimStatus.PAYMENT_CONFIRMED:
        statusText = t('eClaim.history.status.receiveDocument')
        break;
      default: statusText = t('eClaim.history.status.pending')
        break;
    }

    return statusText
  }, [eClaimItem?.status])

  const statusColor = useMemo(() => {
    return eClaimItem?.accept === null ? color.PENDING : eClaimItem?.accept ? color.APPROVE : color.REJECT
  }, [eClaimItem?.accept])
  const backgroundColor = useMemo(() => {
    return eClaimItem?.accept === null ? color.PENDING_LIGHT : eClaimItem?.accept ? color.APPROVE_LIGHT : color.REJECT_LIGHT
  }, [eClaimItem?.accept])


  const onBackPath = useCallback(() => {
    history.push(paths.eClaimHistory())
  }, [history])

  const onCancleButtonPress = useCallback(() => {
    cancelEClaim(id, {
      onSuccess: () => {
        alert('ยกเลิกเรียบร้อย')
        onBackPath()
      },
      onError: () => {
        alert('ไม่สามารถยกเลิกได้')
        onBackPath()
      }
    })
  }, [id, cancelEClaim, onBackPath])



  return (
    <Layout>
      <Text size={24} type="Bold">{t('eClaim.history.detail')}</Text>
      <EClaimInfoContainer>
        <EClaimInfoWrapper>
          <EClaimInfoItems>
            <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.docNo')}</Text>
            <Text size={18} type="SemiBold">{eClaimItem?.docNo}</Text>
          </EClaimInfoItems>
          <EClaimInfoItems>
            <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.status.title')}</Text>
            <EClaimStatusLayout backgroundColor={backgroundColor}>
              <Text size={18} type="SemiBold" color={statusColor}>{statusText}</Text>
            </EClaimStatusLayout>
          </EClaimInfoItems>
          {eClaimItem?.status === EClaimStatus.REJECTED && (
            <EClaimInfoItems>
              <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.remarkByReject')}</Text>
              <Text size={18} type="SemiBold" color={statusColor}>{eClaimItem?.remark}</Text>
            </EClaimInfoItems>
          )}
        </EClaimInfoWrapper>
      </EClaimInfoContainer>
      <EClaimInfoContainer>
        <EClaimInfoWrapper>
          <EClaimInfoItems>
            <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateClaim')}</Text>
            <Text size={18} type="SemiBold">{date}</Text>
          </EClaimInfoItems>
          {eClaimItem?.status === EClaimStatus.REJECTED && (
            <EClaimInfoItems>
              <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateApproveReject')}</Text>
              <Text size={18} type="SemiBold">{eClaimItem?.updatedDate ? dayjs(eClaimItem?.updatedDate).locale(language).format('DD MMM YYYY') : '-'}</Text>
            </EClaimInfoItems>
          )}
          {eClaimItem?.status === EClaimStatus.APPROVED && (
            <EClaimInfoItems>
              <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateApproveReject')}</Text>
              <Text size={18} type="SemiBold">{eClaimItem?.approveDate ? dayjs(eClaimItem?.approveDate).locale(language).format('DD MMM YYYY') : '-'}</Text>
            </EClaimInfoItems>
          )}
          {eClaimItem?.status === EClaimStatus.PAYMENT_CONFIRMED && (
            <>
              <EClaimInfoItems>
                <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateApproveReject')}</Text>
                <Text size={18} type="SemiBold">{eClaimItem?.updatedDate ? dayjs(eClaimItem?.updatedDate).locale(language).format('DD MMM YYYY') : '-'}</Text>
              </EClaimInfoItems>
              <EClaimInfoItems>
                <Text size={14} color={color.FONT_LIGHT_GRAY}>{t('eClaim.history.dateReceiveMoney')}</Text>
                <Text size={18} type="SemiBold">{eClaimItem?.paymentDate ? dayjs(eClaimItem?.paymentDate).locale(language).format('DD MMM YYYY') : '-'}</Text>
              </EClaimInfoItems>
            </>
          )
          }
        </EClaimInfoWrapper>
      </EClaimInfoContainer>
      <Divider />
      {eClaimItem?.accept === null && eClaimItem?.accept !== true && (
        <GroupButtonContainer>
          <ButtonStyle background={color.DARK_RED} fontColor={color.WHITE} onClick={onCancleButtonPress}>
            {t('eClaim.history.cancelClaim')}
          </ButtonStyle>
          <ButtonStyle background={color.WHITE} fontColor={color.PRIMARY} iconImage='IconReload' onClick={onCreateNewEClaim}>
            {t('eClaim.history.claimAgain')}
          </ButtonStyle>
        </GroupButtonContainer>
      )}
    </Layout>
  )
}

export default EClaimInfo