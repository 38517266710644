import React, { useState, useCallback, useEffect, useMemo } from 'react'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import { useGetRelativeById, useGetRelativeTypeList } from '../../services/relative/relative-query'
import Input, { InputLabel } from '../../components/common/Input'
import Select from '../../components/common/Select'
import { useTranslation } from 'react-i18next'

type Value = string | number | undefined | ''
type ModalType = 'CREATE' | 'UPDATE' | 'DELETE'
type RelativeDetailType = {
  title: string,
  firstname: string,
  lastname: string,
  relativeTypeId: number
}

type CreateRelativeProps = {
  visible: boolean
  handleCloseModal: (type: ModalType) => void
  title: string
  handleAction: (params: RelativeDetailType) => void
}
export const CreateRelative = (props: CreateRelativeProps) => {
  const { visible, handleCloseModal, title, handleAction } = props
  const { t } = useTranslation()
  const [relativeDetail, setRelativeDetail] = useState<RelativeDetailType>({
    title: '',
    firstname: '',
    lastname: '',
    relativeTypeId: 1
  })

  const { data: relativeTypeList } = useGetRelativeTypeList()

  const onChangeRelativeDetail = useCallback((name: string, data: Value) => {
    setRelativeDetail(prevState => ({ ...prevState, [name]: data }))
  }, [])

  return (
    <Modal
      visible={visible}
      onCancel={() => handleCloseModal('CREATE')}
      headerComponent={<Text size={24} type='Bold'>{title}</Text>}
      onOk={() => {
        handleAction(relativeDetail)
        handleCloseModal('CREATE')
      }}
      onOkText={t('family.form.submit')}
      onCancelText={t('family.form.cancel')}
    >
      <Input label={t('family.form.title')} onChange={(data) => onChangeRelativeDetail('title', data)} />
      <Input label={t('family.form.firstName')} onChange={(data) => onChangeRelativeDetail('firstname', data)} />
      <Input label={t('family.form.lastName')} onChange={(data) => onChangeRelativeDetail('lastname', data)} />
      <InputLabel>{t('family.form.relationship')}</InputLabel>
      <Select
        options={relativeTypeList?.filter(value => {
          return value.value !== null
        }) || []}
        defaultValue={1}
        onChange={(data: string) => onChangeRelativeDetail('relativeTypeId', data)}
      />
    </Modal>
  )
}

type UpdateRelativeDetailType = RelativeDetailType & { id: number }
type UpdateRelativeProps = {
  id: number
  visible: boolean
  handleCloseModal: (type: ModalType) => void
  title: string
  handleAction: (params: UpdateRelativeDetailType) => void
}
export const UpdateRelative = (props: UpdateRelativeProps) => {
  const { id, visible, handleCloseModal, title, handleAction } = props
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetRelativeById(id)
  const { data: relativeTypeList } = useGetRelativeTypeList()
  const [relativeDetail, setRelativeDetail] = useState<UpdateRelativeDetailType>({
    id: 0,
    title: '',
    firstname: '',
    lastname: '',
    relativeTypeId: 1
  })

  useEffect(() => {
    setRelativeDetail({
      id: data?.id || 0,
      title: data?.title || '',
      firstname: data?.firstname || '',
      lastname: data?.lastname || '',
      relativeTypeId: data?.relativeTypeId || 1
    })
  }, [data])

  const onChangeRelativeDetail = useCallback((name: string, data: Value) => {
    setRelativeDetail(prevState => ({ ...prevState, [name]: data }))
  }, [])

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isError) {
    return <div>Error</div>
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => handleCloseModal('UPDATE')}
      headerComponent={<Text size={24} type='Bold'>{title}</Text>}
      onOk={() => {
        console.log(relativeDetail)
        handleAction(relativeDetail)
        handleCloseModal('UPDATE')
      }}
      onOkText={t('family.form.submit')}
      onCancelText={t('family.form.cancel')}
    >
      <Input label={t('family.form.title')} defaultValue={data?.title} onChange={(data) => onChangeRelativeDetail('title', data)} />
      <Input label={t('family.form.firstName')} defaultValue={data?.firstname} onChange={(data) => onChangeRelativeDetail('firstname', data)} />
      <Input label={t('family.form.lastName')} defaultValue={data?.lastname} onChange={(data) => onChangeRelativeDetail('lastname', data)} />
      <InputLabel>{t('family.form.relationship')}</InputLabel>
      <Select
        options={relativeTypeList?.filter(value => {
          return value.value !== null
        }) || []}
        defaultValue={data?.relativeTypeId}
        onChange={(data: string) => onChangeRelativeDetail('relativeTypeId', data)}
      />
    </Modal>
  )
}

type DeleteRelativeProps = {
  id: number
  visible: boolean
  handleCloseModal: (type: ModalType) => void
  title: string
  handleAction: (id: number) => void
}
export const DeleteRelative = (props: DeleteRelativeProps) => {
  const { id, visible, handleCloseModal, title, handleAction } = props
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetRelativeById(id)
  const { data: relativeTypeList } = useGetRelativeTypeList()

  const relativeTypeName = useMemo(() => {
    return relativeTypeList?.find(value => {
      return value.value === data?.relativeTypeId
    })?.label || ''
  }, [relativeTypeList, data])

  return (
    <Modal
      visible={visible}
      onCancel={() => handleCloseModal('DELETE')}
      headerComponent={<Text size={24} type='Bold'>{title}</Text>}
      onOk={() => {
        handleAction(id)
        handleCloseModal('DELETE')
      }}
      onOkText={t('family.form.submit')}
      onCancelText={t('family.form.cancel')}
    >
      <Text size={20} type='Bold'>{`${data?.title}${data?.firstname} ${data?.lastname}`}</Text>
      <Text>{t('family.form.relationship')}: {`${relativeTypeName}`}</Text>
    </Modal>
  )
}


