export const PRIMARY = '#472F92'
export const SHOP_PRIMARY = '#55B6E3'
export const SECONDARY = '#472F92'

export const WHITE = '#FFFFFF'
export const BACKGROUND = '#FFFFFF'
export const BACKGROUND_LIGHT_GRAY = '#F4F4F4'
export const BACKGROUND_LIGHT = '#F2F2F2'

export const DARK_RED = '#A61C1A'

export const BLACK = '#333333'
export const FONT_BLACK = '#363640'

export const LIGHT_GRAY = '#C1C1C1'
export const MEDIUM_GRAY = '#9F9F9F'
export const DARK_GRAY = '#4D4D4D'
export const FONT_LIGHT_GRAY = '#8C8C8C'
export const FONT_DARK_GRAY = '#636363'

export const LIGHT_BLUE = '#45BFF9'

export const ORANGE = '#FE7339'
export const LIGHT_ORANGE = '#FFF1EB'

export const YELLOW_STAR = '#FFCA27'

export const GREEN = '#50B948'

export const BORDER = '#E5E5E5'
export const INPUT_LABLE = '#3F3D56'
export const INPUT_ERROR = '#C44536'

export const ADD_APP_ICON = '#DADFE3'
export const HORIZONTAL_BAR = '#E1F1FE'

export const APPROVE = '#00B39C'
export const APPROVE_LIGHT = '#D2EAE4'
export const REJECT = '#2B2B2B'
export const REJECT_LIGHT = '#F5F6F6'
export const PENDING = '#EA9C00'
export const PENDING_LIGHT = '#FEF9F3'

export const PINK = '#ec4899'