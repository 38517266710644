import styled from '@emotion/styled'
import Card from './common/Card'
import Image, { ImageNameKeys } from './common/Image'
import Text from './common/Text'
import { Tooltip } from 'antd'
import { mobile, mobileVerySmall, useScreen } from '../utils/responsive-helper'
import { numberWithCommas } from '../utils/helper'
import iconNews from '../assets/images/base/icon-news.png'
import { isTablet } from 'react-device-detect'
import color from '../constant/colors'
const LayoutCard = styled(Card)`
  height: 300px;
  ${mobile} {
    height: 100%;
    width: 100%;
    margin: 2px;
  }
  ${mobileVerySmall} {
    height: 100%;
    width: 100%;
    margin: 8px;
  }
`
const LayoutFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
`

const LayoutRowImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 27px;
`

const LayoutRowTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 8px;
  text-align: center;
`

const LayoutFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-heigth: 100px;
  border-top: solid 1px #f0f0f0;
  width: 100%;
  padding: 5px;
  margin-bottom: 5px;
`

const LayoutFooterContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const LayoutFooterIcon = styled.div`
  align-self: center;
`

const LayoutFooterText = styled.div`
  padding: 12px;
  padding-left: 0px;
  margin-left: 4px;
  margin-right: 4px;
  text-align: end;
  width: 100%;

  .lable-use-coin {
    ${mobile} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .available-amount-text {
    ${mobile} {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
const ImageSize = styled(Image)`
  width: 40px;
  height: 40px;
  ${mobile} {
    width: 20px;
    height: 25px;
  }
  ${mobileVerySmall} {
    width: 40px;
    height: 40px;
  }
`
const TextEllipsis = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  ${mobile} {
    -webkit-line-clamp: 6;
  }
  ${mobileVerySmall} {
    -webkit-line-clamp: 3;
  }
`

type CustomCardProps = {
  height?: number
  icon: string
  title: string
  maxClaimablePrice?: number
  availableAmount?: number
  onClick?: () => void
}

const WellBeingCard = (props: CustomCardProps) => {
  const { onClick, icon, title, maxClaimablePrice, availableAmount } = props
  const mainIcons = icon ? icon : iconNews
  const labelUseCoin = `${numberWithCommas(maxClaimablePrice || 0)} คะแนน`

  const availableAmountText = `(คงเหลือ ${numberWithCommas(availableAmount || 0)} คะแนน)`

  const a = `(คงเหลือ`
  const textScg = ` ${numberWithCommas(availableAmount || 0)} คะแนน)`
  const { isMobile } = useScreen()
  const fontSize = !isMobile ? 20 : 16
  const pointFontSize = !isMobile || isTablet ? 16 : 12
  return (
    <LayoutCard className="well-being-card" onClick={onClick}>
      <LayoutFlexColumn>
        <LayoutBody>
          <LayoutRowImage>
            {icon ? (
              <Image src={icon} width={48} height={48} />
            ) : (
              <Image imageName="iconNews" width={48} height={48} />
            )}
          </LayoutRowImage>
          <Tooltip title={title}>
            <LayoutRowTitle>
              <TextEllipsis size={fontSize} type={'Bold'} color={color.PRIMARY}>
                {title}
              </TextEllipsis>
            </LayoutRowTitle>
          </Tooltip>
        </LayoutBody>

        <LayoutFooter>
          <LayoutFooterContent>
            <LayoutFooterIcon>
              <ImageSize imageName={'iconCoinWithBackground'} style={{ objectFit: 'none' }} />
            </LayoutFooterIcon>
            <Tooltip title={labelUseCoin}>
              <LayoutFooterText>
                <Text
                  size={pointFontSize}
                  className="lable-use-coin"
                  maxLine={1}
                  type="Bold"
                  color={color.PRIMARY}
                >
                  {labelUseCoin}
                </Text>
                <Text
                  size={pointFontSize}
                  className="available-amount-text"
                  maxLine={1}
                  type={'Medium'}
                >
                  {availableAmountText}
                </Text>
              </LayoutFooterText>
            </Tooltip>
          </LayoutFooterContent>
        </LayoutFooter>
      </LayoutFlexColumn>
    </LayoutCard>
  )
}

export default WellBeingCard
