import React from 'react'
import Text from '../../components/common/Text'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { mobile } from '../../utils/responsive-helper'

const BreadcrumbLayout = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin : 24px 16px;
  }
`

const ContentLayout = styled.div`
  background-color: ${color.WHITE};
  margin-bottom: 10px;
  padding: 32px;
  border-radius: 8px;
`

const CenterLayout = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  /* border: 1px solid #000000; */
  margin-bottom: 24px;
`

const WhiteLine = styled.div`
  height: 20px;
`

const TermAndCondition = () => {
  const { t } = useTranslation()

  return (
    <>
      {/* <BreadcrumbLayout>
        <Breadcrumb
          overide={(paths) => {
            paths = [
              { label: 'Home', path: '/' },
              { label: t('termAndCondition.header'), path: '/term_and_condition' },
            ]
            return paths
          }}
        />
      </BreadcrumbLayout> */}
      <CenterLayout>
        <Text size={28} type="Bold" color={color.WHITE}>
          {t('termAndCondition.header')}
        </Text>
      </CenterLayout>
      <ContentLayout>
        <Text size={20} type="Medium">
          {t('termAndCondition.subHeader')}
        </Text>
        <WhiteLine />
        <Text size={24} type="Bold">
          {t('termAndCondition.webAccessHeader')}
        </Text>
        <Text size={20} type="Medium">
          {t('termAndCondition.webAccessText')}
        </Text>
        <WhiteLine />
        <Text size={24} type="Bold">
          {t('termAndCondition.changeTermHeader')}
        </Text>
        <Text size={20} type="Medium">
          {t('termAndCondition.changeTermText')}
        </Text>
        <WhiteLine />
        <Text size={24} type="Bold">
          {t('termAndCondition.lawHeader')}
        </Text>
        <Text size={20} type="Medium">
          {t('termAndCondition.lawText')}
        </Text>
        <WhiteLine />
      </ContentLayout>
    </>
  )
}

export default TermAndCondition
