import styled from '@emotion/styled'
import { getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import {
  DatePickerField,
  InputField,
  SelectField,
  UploadfileField,
} from '../../components/common/fields'
import Card from '../../components/common/Card'
import Text from '../../components/common/Text'
import { mobile, mobileVerySmall } from '../../utils/responsive-helper'
import moment from 'moment'
import FooterContent from '../../components/FooterContent'
import Button from '../../components/common/Button'
import { EClaimFormParams, EClaimItem } from '../../services/e-claim/e-claim-types'
import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EClaimInf, setClaimInfo } from '../../redux-store/e-claim-reducer'
import { useHistory } from 'react-router'
import * as paths from '../../constant/path'
import Input, { InputLabel } from '../../components/common/Input'
import color from '../../constant/colors'
import { useGetUser, useGetUserCoin } from '../../services/user/user-query'
import {
  useCreateUserClaim,
  useGetRelativeOptionScg,
  useUploadFile,
  useUploadReceiptImage,
} from '../../services/e-claim/e-claim-query'
import { useCreateRelative } from '../../services/relative/relative-query'
import { CreateRelativeParams } from '../../services/relative/relative-typed'
import { Moment } from 'moment'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import { numberWithCommas, useRelativeTypeName } from '../../utils/helper'
import { Divider, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { CreateRelative } from '../Relative/component'
import { useTranslation } from 'react-i18next'

const ClaimFormContainer = styled(Card)`
  padding: 0px 16px 16px;
  .layout-upload {
    ${mobile} {
      width: 100%;
    }
    ${mobileVerySmall} {
      width: 100%;
    }
  }
  ${mobile} {
    padding: 16px;
  }
  ${mobileVerySmall} {
    padding: 16px;
  }
`

const Spacer = styled.div`
  width: 20px;
  height: 20px;

  ${mobile} {
    display: none;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  ${mobile} {
    flex-direction: column;
  }
`

const CustomEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;

  ${mobile} {
    justify-content: center;
  }
`

const CustomUnEditableFooter = styled(FooterContent)`
  height: 80;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  column-gap: 16px;
  
  ${mobile} {
    flex-direction: column;
    justify-content: center;
  }
`

const SumPointContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;

  ${mobile} {
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const BackToEditButton = styled(Button)`
  width: 267px;
`
const SubmitButton = styled(Button)`
  width: 267px;
`
const TextModal = styled(Text)`
  text-align: center;
`

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  /* border: 1px solid; */
  margin-bottom: 8px;
  padding-bottom: 0;

  ${mobile} {
    width: 100%;
  }
`

const RelativeField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 8px;
`

const CreateRelativeButton = styled(Button)`
  width: fit-content;
`

export const ECLAIM_FORM_NAME = 'ECLAIM_FORM'
type EClaimParam = {
  eClaimItem?: EClaimItem
  editable?: boolean
  eClaimFormValue?: EClaimInf['claimInfo']
  isPreview?: boolean
  allpayStatus?: boolean
  isHistory?: boolean
  claimTypeId?: string
  isCreateNew?: 'true' | 'false'
}
const required = (value: string) => (value ? undefined : 'error.required')
const requiredDate = (value: Moment) => {
  const x = moment(value).isValid() ? undefined : 'error.required'
  return x
}
const EClaimForm: ComponentType<
  EClaimParam & InjectedFormProps<EClaimFormParams, EClaimParam, string>
> = (props) => {
  const dispatch = useDispatch()
  const {
    handleSubmit,
    invalid,
    eClaimItem,
    editable = true,
    eClaimFormValue,
    isPreview = false,
    allpayStatus,
    isHistory = false,
    claimTypeId,
    initialize,
    isCreateNew
  } = props

  const formValuesSelector = getFormValues(ECLAIM_FORM_NAME)
  const formValues = useSelector<any, EClaimFormParams>(
    (state) => formValuesSelector(state) as EClaimFormParams,
  )
  const { t } = useTranslation()
  const [visible, modalAction] = useVisibility()
  const [visibleCreate, setVisibleCreate] = useVisibility()
  const [errorMessage, setErrorMessage] = useState('')
  const { data: user } = useGetUser()
  const { firstName, lastName } = user || {}

  const fullname = `${firstName} ${lastName}`
  const history = useHistory()
  const { data: userCoin = 0 } = useGetUserCoin()
  const { data: relativesOptions, refetch: refetchRelative } = useGetRelativeOptionScg(eClaimItem?.relativeTypeId)
  const { mutate: createUserClaim, isLoading: isSubmitting } = useCreateUserClaim()
  const { mutate: createRelative } = useCreateRelative()
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadFile()
  // const { mutateAsync: uploadReceiptImage, isLoading: isUploadingReceipt, isError: isErrorUplaodReceiptImage } = useUploadReceiptImage()
  const relativeTypeName = useRelativeTypeName()

  useEffect(() => {
    if (isCreateNew) {
      initialize({
        userClaimPrice: eClaimFormValue?.userClaimPrice ? eClaimFormValue?.userClaimPrice : undefined,
        exchangeCoin: eClaimFormValue?.exchangeCoin ? eClaimFormValue?.exchangeCoin : undefined,
        userRemark: eClaimFormValue?.userRemark ? eClaimFormValue?.userRemark : '',
        receiptFile: eClaimFormValue?.receiptFile ? eClaimFormValue?.receiptFile : '',
        optionalFileUrl: eClaimFormValue?.optionalFileUrl ? eClaimFormValue?.optionalFileUrl : '',
        relativeId: eClaimFormValue?.relativeId ? eClaimFormValue?.relativeId : '0',
      })
    } else {
      initialize({
        userClaimPrice: formValues?.userClaimPrice ? formValues?.userClaimPrice : undefined,
        exchangeCoin: formValues?.exchangeCoin ? formValues?.exchangeCoin : undefined,
        userRemark: formValues?.userRemark ? formValues?.userRemark : '',
        receiptFile: formValues?.receiptFile ? formValues?.receiptFile : '',
        optionalFileUrl: formValues?.optionalFileUrl ? formValues?.optionalFileUrl : '',
        relativeId: formValues?.relativeId ? formValues?.relativeId : '0',
      })
    }
  }, [])

  const exchangeCoinFormated = useMemo(() => {
    return eClaimFormValue?.exchangeCoin.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }, [eClaimFormValue?.exchangeCoin])

  const userRestCoinFormated = useMemo(() => {
    const restCoin = userCoin - (eClaimFormValue?.exchangeCoin || 0)
    return restCoin.toLocaleString(undefined, { maximumFractionDigits: 2 })
  }, [userCoin, eClaimFormValue])

  const convertBase64ToFile = useCallback(async (imageBase64: string) => {
    let type = 'image/png'
    let ext = 'png'
    switch (imageBase64.split('/')[1].split(';')[0]) {
      case 'x-zip-compressed':
        type = 'zip'
        break;
      case 'zip':
        type = 'zip'
        break;
      case 'pdf':
        type = 'pdf'
        break;
      default: type = 'image/png'
        break;
    }
    const res = await fetch(imageBase64)
    const blobFile = await res.blob()
    if (blobFile.type.split('/')[1].split(';')[0] === 'x-zip-compressed' || blobFile.type.split('/')[1].split(';')[0] === 'zip') {
      ext = 'zip'
    } else {
      ext = blobFile.type.split('/')[1].split(';')[0]
    }
    const file = new File([blobFile], `${Date.now()}.${ext}`, { type })
    return file
  }, [])

  const onSubmit = useCallback(
    (form: EClaimFormParams) => {
      dispatch(
        setClaimInfo({
          id: eClaimItem?.id,
          name: form.name,
          exchangeCoin: form.exchangeCoin,
          userClaimPrice: form.userClaimPrice,
          optionalFileUrl: form.optionalFileUrl,
          receiptFile: form.receiptFile,
          userRemark: form.userRemark,
          relativeId: form.relativeId
        }),
      )
      if (editable === true) {
        history.push(paths.eClaimSummary({ routeParam: { eClaimId: eClaimItem?.claimId || 0 }, queryParam: { claimTypeId: claimTypeId || '1' } }))
      }
    },
    [dispatch, eClaimItem?.claimId, editable, history, eClaimItem?.id, claimTypeId],
  )

  const getImageUploadUrl = useCallback(async (image) => {
    try {
      const imageFile = await convertBase64ToFile(image || '')
      const imageFileUrl = await uploadFile(imageFile)

      return imageFileUrl
    } catch (error) {
      console.log("error", error)

      return ''
    }
  }, [convertBase64ToFile, uploadFile])

  const onSubmitApplication = useCallback(async () => {
    //uploadfile
    const optionalFileUrl = await getImageUploadUrl(eClaimFormValue?.optionalFileUrl || '')
    const receiptImageURL = await getImageUploadUrl(eClaimFormValue?.receiptFile || '')
    createUserClaim(
      {
        id: eClaimFormValue?.id,
        claimId: eClaimItem?.claimId || 0,
        claimName: fullname,
        optionalFileUrl: optionalFileUrl || '',
        receiptFile: eClaimFormValue?.receiptFile ? receiptImageURL : '',
        exchangeCoin: eClaimFormValue?.exchangeCoin!,
        userClaimPrice: eClaimFormValue?.userClaimPrice!,
        userRemark: eClaimFormValue?.userRemark || '',
        relativeId:
          eClaimFormValue?.relativeId === '0' ? undefined : eClaimFormValue?.relativeId,
        allpayRef: eClaimFormValue?.allpayRef ? eClaimFormValue?.allpayRef : '',
      },
      {
        onSuccess: () => {
          history.push(
            paths.eClaimCompleted({
              routeParam: { eClaimId: eClaimItem?.claimId || 0 },
              queryParam: {
                eClaimName: eClaimItem?.claimName || '',
                eClaimPrice: eClaimFormValue?.exchangeCoin || 0,
                eClaimTypeId: claimTypeId || '1',
              },
            }),
          )
        },
        onError: (error) => {
          setErrorMessage(error.message)
          modalAction.show()
        },
      },
    )
  }, [
    getImageUploadUrl,
    createUserClaim,
    eClaimFormValue?.id,
    eClaimItem?.claimId,
    eClaimItem?.claimName,
    eClaimFormValue?.receiptFile,
    eClaimFormValue?.optionalFileUrl,
    eClaimFormValue?.exchangeCoin,
    eClaimFormValue?.userClaimPrice,
    eClaimFormValue?.userRemark,
    eClaimFormValue?.relativeId,
    eClaimFormValue?.allpayRef,
    fullname,
    history,
    modalAction,
    claimTypeId
  ])

  const onBackToEdit = useCallback(() => {
    history.push(paths.eClaimDetail({ routeParam: { eClaimId: eClaimItem?.claimId || 0 }, queryParam: { claimTypeId: claimTypeId || '1' } }))
  }, [claimTypeId, eClaimItem?.claimId, history])

  const onCreateRelative = useCallback((relativeDetail: CreateRelativeParams) => {
    createRelative(relativeDetail, {
      onSuccess: () => {
        console.log('Create success')
        refetchRelative()
      },
      onError: (error: any) => {
        console.log('Create error')
        if (error.message === 'Reach maximum number of relative') {
          alert(`ข้อมูล ${relativeTypeName(relativeDetail.relativeTypeId)} เกินจำนวนที่กำหนด`)
        }
      }
    })
  }, [createRelative, refetchRelative, relativeTypeName])

  const validateImage = useMemo(() => {
    const hasSomeImage = formValues?.optionalFileUrl || formValues?.receiptFile
    return isPreview === false && !hasSomeImage ? [required] : undefined
  }, [formValues?.optionalFileUrl, formValues?.receiptFile, isPreview])

  const validateClaimAmount = useCallback(
    (value) => {
      if (eClaimItem?.claimPrice && value > eClaimItem?.claimPrice) {
        return 'eClaim.claimPriceError'
      }
    },
    [eClaimItem?.claimPrice],
  )

  const isEnoughCoin = useCallback(
    (value) => {
      if (value > userCoin) {
        return 'eClaim.PointError'
      }
    },
    [userCoin])

  const isNotZero = useCallback(
    (value) => {
      if (value === '0') {
        return 'error.required'
      }
    }
    , [])

  const nameRelative = useCallback(
    (relativeId) => {
      const data = relativesOptions?.map((relativesOption) => {
        const { value, label } = relativesOption
        if (relativeId === value) {
          return label
        }
      })
      return data?.length === 0 ? 'ตัวเอง' : data
    },

    [relativesOptions],
  )

  const fullNameSummary = useMemo(() => {
    return eClaimFormValue?.relativeId === '0' ? fullname : nameRelative(eClaimFormValue?.relativeId)
  }, [eClaimFormValue?.relativeId, fullname, nameRelative])

  const disbleField = allpayStatus ? true : false

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => {
          modalAction.hide()
        }}
        onCancelText="ตกลง"
      >
        <div style={{ margin: '30px' }}>
          <TextModal size={24} type="Bold">
            {errorMessage}
          </TextModal>
          <TextModal>กรุณาตรวจสอบอีกครั้ง</TextModal>
          <Divider />
        </div>
      </Modal>
      <CreateRelative
        visible={visibleCreate}
        handleCloseModal={setVisibleCreate.hide}
        handleAction={onCreateRelative}
        title={t('family.addTitle')}
      />
      <ClaimFormContainer>
        <InputContainer>
          {editable && (
            <>
              {claimTypeId === '1' ? (
                <FlexCol>
                  <InputLabel>{t('eClaim.detail.form.nameRequest')}</InputLabel>
                  <Text size={24} type='Bold'>{fullname}</Text>
                </FlexCol>
              ) : (
                <FlexCol>
                  <InputLabel>
                    {t('eClaim.detail.form.claimFor')}{' '}
                  </InputLabel>
                  <RelativeField>
                    <SelectField
                      name="relativeId"
                      placeholder={t('eClaim.detail.form.claimFor')}
                      validate={[required, isNotZero]}
                      options={relativesOptions?.filter(value => {
                        return value.value !== null
                      }) || []}
                      disabled={disbleField}
                      style={{ width: '100%' }}
                    />
                    <CreateRelativeButton onClick={setVisibleCreate.show}>{t('family.addTitle')}</CreateRelativeButton>
                  </RelativeField>
                </FlexCol>
              )}
            </>
          )}
          {isHistory && (
            <>
              <FlexCol>
                <InputLabel size={18}>{t('eClaim.detail.form.claimFor')}</InputLabel>
                <Text size={24} type='Bold'>{eClaimItem?.relativeId ? nameRelative(eClaimItem?.relativeId) : fullname}</Text>
              </FlexCol>
            </>
          )}
          {isPreview && (
            <FlexCol>
              <InputLabel size={18}>{t('eClaim.detail.form.claimFor')}</InputLabel>
              <Text size={24} type='Bold'>{fullNameSummary}</Text>
            </FlexCol>
          )}
          <FlexCol>
            {editable && (
              <InputField
                name="userClaimPrice"
                label={t('eClaim.detail.form.amountReceipt')}
                placeholder={t('eClaim.detail.form.inputAmount')}
                validate={[required, validateClaimAmount, isNotZero]}
                inputType="number"
                disabled={disbleField}
              />
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.amountReceipt')}</InputLabel>
                <Text type="Bold" size={24}>
                  {eClaimFormValue?.userClaimPrice ? numberWithCommas(Number(eClaimFormValue?.userClaimPrice)) : 0}
                </Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.amountReceipt')}</InputLabel>
                <Text type="Bold" size={24}>
                  {eClaimItem?.userClaimPrice ? numberWithCommas(Number(eClaimItem?.userClaimPrice)) : 0}
                </Text>
              </>
            )}
          </FlexCol>
          <FlexCol>
            {editable && (
              <InputField
                name="exchangeCoin"
                label={t('eClaim.detail.form.claimAmount')}
                placeholder={t('eClaim.detail.form.inputAmount')}
                validate={[required, validateClaimAmount, isEnoughCoin, isNotZero]}
                inputType="number"
                disabled={disbleField}
              />
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={24}>
                  {eClaimFormValue?.exchangeCoin ? numberWithCommas(Number(eClaimFormValue?.exchangeCoin)) : 0}
                </Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.claimAmount')}</InputLabel>
                <Text type="Bold" size={24}>
                  {eClaimItem?.exchangeCoin ? numberWithCommas(Number(eClaimItem?.exchangeCoin)) : 0}
                </Text>
              </>
            )}
          </FlexCol>
          <FlexCol>
            {editable && (
              <InputField
                name="userRemark"
                label={t('eClaim.detail.form.remark')}
                placeholder={t('eClaim.detail.form.detail')}
                disabled={disbleField}
              />
            )}
            {isPreview && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.remark')}</InputLabel>
                <Text type="Bold" size={24}>{eClaimFormValue?.userRemark ? eClaimFormValue?.userRemark : '-'} </Text>
              </>
            )}
            {isHistory && (
              <>
                <InputLabel size={18}>{t('eClaim.detail.form.remark')}</InputLabel>
                <Text type="Bold" size={24}>{eClaimItem?.userRemark ? eClaimItem?.userRemark : '-'} </Text>
              </>
            )}
          </FlexCol>
        </InputContainer>
        <Text size={24} type="Bold">
          {t('eClaim.detail.form.attachDocuments')}
        </Text>
        <FileUploadContainer>
          {(eClaimFormValue?.receiptFile || isPreview === false) && (
            <UploadfileField
              name="receiptFile"
              icon={'receiptIcon'}
              title={`1. ${t('eClaim.detail.form.receipt')}`}
              desc={t('app.upload.conditionUpload')}
              value={isHistory ? eClaimItem?.receiptFile : eClaimFormValue?.receiptFile}
              isDelete={isPreview || isHistory}
              validate={validateImage}
              maximumFileSize={3}
              disabled={disbleField}
            />
          )}
          {(eClaimFormValue?.optionalFileUrl || isPreview === false) && (
            <UploadfileField
              name="optionalFileUrl"
              icon={'receiptIcon'}
              title={`2. ${t('eClaim.detail.form.medicalCertificate')}`}
              desc={t('app.upload.conditionUpload')}
              value={isHistory ? eClaimItem?.optionalFileUrl : eClaimFormValue?.optionalFileUrl}
              isDelete={isPreview || isHistory}
              validate={validateImage}
              maximumFileSize={3}
              disabled={disbleField}
            />
          )}
        </FileUploadContainer>
      </ClaimFormContainer>
      {editable && !isHistory && (
        <CustomEditableFooter>
          <SubmitButton onClick={handleSubmit(onSubmit)} disabled={invalid || disbleField}>
            {t('eClaim.detail.form.next')}
          </SubmitButton>
        </CustomEditableFooter>
      )}
      {!editable && !isHistory && (
        <CustomUnEditableFooter>
          <SumPointContainer>
            <Text type="Bold">{t('eClaim.detail.form.totalAmount')}</Text>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Text type="Bold" color={color.INPUT_ERROR}>
                -{exchangeCoinFormated}
              </Text>
              &nbsp;
              <Text color={color.FONT_BLACK}>{t('app.currency')}</Text>&nbsp;
              <Text color={color.FONT_LIGHT_GRAY}>{`(${t('app.remain')} ${userRestCoinFormated} ${t('app.currency')})`}</Text>
            </div>
          </SumPointContainer>
          <BackToEditButton onClick={onBackToEdit} background={color.WHITE} fontColor={color.PRIMARY}>
            {t('eClaim.detail.form.editInfo')}
          </BackToEditButton>
          <SubmitButton onClick={onSubmitApplication} disabled={isSubmitting || isUploading}>
            {t('eClaim.detail.form.submit')}
          </SubmitButton>
        </CustomUnEditableFooter>
      )}
    </>
  )
}

export default reduxForm<EClaimFormParams, EClaimParam, string>({
  form: ECLAIM_FORM_NAME,
  // initialValues: {
  //   relativeTypeId: 'ตัวเอง',
  // },
})(EClaimForm)
