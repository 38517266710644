import styled from '@emotion/styled'
import Image from '../common/Image'
import Text from '../common/Text'
import Button from '../common/Button'
import color from '../../constant/colors'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import { useHistory } from 'react-router'
import { playDetail } from '../../constant/path'
import { useGetMyPlay } from '../../services/play/play-query'
import moment from 'moment'
import config from '../../config'
import { useTranslation } from 'react-i18next'

const CardPoint = styled.div`
  border-radius: 8px;
  width: 45%;
  margin: 16px;
  display: inline-block;

  ${mobile} {
    width: 100%;
  }
`
const FlexRowBaseLine = styled.div`
  padding-left: 8px;
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const ImageStyle = styled(Image)`
  object-fit: none;
  align-self: center;
`

const TextPaddingLeft = styled(Text)`
  padding-left: 8px;
`

const CustomStyleImage = styled(Image)`
  width: 100%;
  height: 250px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`

const TextTitle = styled(Text)`
  padding-top: 12px;
  padding-left: 8px;
`

const ButtonStyled = styled(Button)`
  margin-top: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const PlayCardList = () => {

  const history = useHistory()
  const { t } = useTranslation()

  const { data } = useGetMyPlay({
    size: 10
  })

  function onButtonPress(playId: number) {
    history.push(playDetail({ routeParam: { playId } }))
  }

  return (
    <Container>
      {
        data?.pages[0]?.data.map((item, index) => {
          let imagePath = item.playEvent.playEventImagesList?.[0]?.imagePath
          let absolutePath = imagePath?.indexOf('http') > -1 ? imagePath : `${config.apiHost}${imagePath}`
          return (
            <CardPoint key={index}>
              <CustomStyleImage src={item.playEvent.playEventImagesList.length !== 0 ? `${absolutePath}` : ''} />

              <TextTitle type="SemiBold" size={18}>
                {item.playEvent.title}
              </TextTitle>

              <FlexRowBaseLine>
                <ImageStyle width={16} height={16} imageName={'calendarIcon'} />
                <TextPaddingLeft size={12}>
                  {moment(item.playEvent.startDate).format('YYYY-MM-DD')} - {moment(item.playEvent.endDate).format('YYYY-MM-DD')}
                </TextPaddingLeft>
              </FlexRowBaseLine>
              <ButtonStyled
                onClick={() => onButtonPress(item.playEvent.id)}
                background='#000000'
                textProps={{ color: color.WHITE }}
              >
                <Text size={16} type='Bold' color={color.WHITE}>
                  {t('play.cardList.button')}
                </Text>
              </ButtonStyled>
            </CardPoint>
          )
        })
      }
    </Container>
  )
}

export default PlayCardList
