import React from 'react'
import styled from '@emotion/styled'
import Brand from './Brand'
import ImageSlider from './ImageSlider'
import ProductCategoryList from './ProductCategoryList'
import ProfileCard from '../../components/ProfileCard'
import Breadcrumb from '../../components/common/Breadcrumb'
import { mobile, tablet } from '../../utils/responsive-helper'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }

  .image-slider {
    width: 752px;
    height: 420px;
    object-fit: contain;
    margin-right: 24px;

    ${tablet} {
      max-width: unset;
      width: 100%;
    }
    ${mobile} {
      height: 192px;
    }
  }

  .profile-card {
    ${tablet} {
      margin-bottom: 16px;
    }
  }
`

const FlexRow = styled.div`
  display: flex;
  ${tablet} {
    flex-direction: column-reverse;
  }
`

const BreadcrumbStyled = styled<typeof Breadcrumb>(Breadcrumb)`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin : 24px 16px;
  }
`

const Shop = () => {
  return (
    <Layout>
      <BreadcrumbStyled />
      <FlexRow>
        <ImageSlider />
        <ProfileCard size="medium" />
      </FlexRow>
      <Brand />
      <ProductCategoryList />
      {/* <Physical />*/}
    </Layout>
  )
}

export default Shop
