import React, { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import Headbar from '../../components/common/HeadBar'
import * as paths from '../../constant/path'
import colors from '../../constant/colors'
import Text from '../../components/common/Text'
import { Avatar, List } from 'antd';
import Button from '../../components/common/Button'
import {
  useGetRelative,
  useDeleteRelativeById,
  useCreateRelative,
  useUpdateRelativeById
} from '../../services/relative/relative-query'
import { CreateRelativeParams } from '../../services/relative/relative-typed'
import { UpdateRelative, CreateRelative, DeleteRelative } from './component'
import { useRelativeTypeName } from '../../utils/helper'
import { useTranslation } from 'react-i18next'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentLayout = styled.div`
  background-color: ${colors.WHITE};
  padding: 16px;
  border-radius: 16px;
  min-height: 500px;
`
const UpdateButton = styled.div`
  color: ${colors.SECONDARY};
  cursor: pointer;
`
const DeleteButton = styled.div`
  color: ${colors.INPUT_ERROR};
  cursor: pointer;
`
const CreateButton = styled(Button)`
  align-self: center;
`
const RelativeName = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.BLACK};
`

type ModalType = 'CREATE' | 'UPDATE' | 'DELETE'

const Relative = () => {
  const { t } = useTranslation()
  const { data: relativeList, refetch } = useGetRelative()
  const relativeTypeName = useRelativeTypeName()
  const { mutate: deleteRelative } = useDeleteRelativeById()
  const { mutate: createRelative } = useCreateRelative()
  const { mutate: updateRelative } = useUpdateRelativeById()
  const [selectedRelativeId, setSelectedRelativeId] = React.useState<number>(0)

  const [visibleCreate, setVisibleCreate] = React.useState(false)
  const [visibleUpdate, setVisibleUpdate] = React.useState(false)
  const [visibleDelete, setVisibleDelete] = React.useState(false)

  const handleOpenModal = useCallback((type: ModalType) => {
    switch (type) {
      case 'CREATE':
        setVisibleCreate(true);
        break;
      case 'UPDATE':
        setVisibleUpdate(true);
        break;
      case 'DELETE':
        setVisibleDelete(true);
        break;
      default:
        break;
    }
  }, [])
  const handleCloseModal = useCallback((type: ModalType) => {
    switch (type) {
      case 'CREATE':
        setVisibleCreate(false);
        break;
      case 'UPDATE':
        setVisibleUpdate(false);
        break;
      case 'DELETE':
        setVisibleDelete(false);
        break;
      default:
        break;
    }
  }, [])

  const onCreateRelative = useCallback((relativeDetail: CreateRelativeParams) => {
    createRelative(relativeDetail, {
      onSuccess: () => {
        console.log('Create success')
        refetch()
      },
      onError: (error: any) => {
        console.log('Create error')
        if (error.message === 'Reach maximum number of relative') {
          alert(`ข้อมูล ${relativeTypeName(relativeDetail.relativeTypeId)} เกินจำนวนที่กำหนด`)
        }
      }
    })
  }, [createRelative, refetch, relativeTypeName])

  const onUpdateRelative = useCallback((relativeDetail: CreateRelativeParams) => {
    updateRelative(relativeDetail, {
      onSuccess: () => {
        console.log('Create success')
      },
      onError: (error: any) => {
        console.log('Create error')
        if (error.message === 'Reach maximum number of relative') {
          alert(`ข้อมูล ${relativeTypeName(relativeDetail.relativeTypeId)} เกินจำนวนที่กำหนด`)
        }
      }
    })
  }, [updateRelative, relativeTypeName])

  const onDeleteRelative = useCallback((id: number) => {
    deleteRelative(id, {
      onSuccess: () => {
        console.log('Delete success')
        setSelectedRelativeId(0)
        refetch()
      },
      onError: () => {
        console.log('Delete error')
      }
    })
  }, [deleteRelative, refetch])

  return (
    <Layout>
      <CreateRelative
        visible={visibleCreate}
        handleCloseModal={handleCloseModal}
        handleAction={onCreateRelative}
        title={t('family.addTitle')}
      />
      <UpdateRelative
        visible={visibleUpdate}
        handleCloseModal={handleCloseModal}
        handleAction={onUpdateRelative}
        title={t('family.editTitle')}
        id={selectedRelativeId}
      />
      <DeleteRelative
        visible={visibleDelete}
        handleCloseModal={handleCloseModal}
        handleAction={onDeleteRelative}
        title={t('family.deleteTitle')}
        id={selectedRelativeId}
      />
      <Headbar title={t('family.title')} backWard={paths.eClaim()} />
      <ContentLayout>
        <Text size={24} type='Bold'>{t('family.familyList')}</Text>
        <List
          itemLayout="horizontal"
          dataSource={relativeList}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                <UpdateButton onClick={() => {
                  setSelectedRelativeId(item.id)
                  handleOpenModal('UPDATE')
                }}>
                  {t('family.edit')}
                </UpdateButton>,
                <DeleteButton onClick={() => {
                  setSelectedRelativeId(item.id)
                  handleOpenModal('DELETE')
                }}>
                  {t('family.delete')}
                </DeleteButton>
              ]}
            >
              <List.Item.Meta
                title={<Text size={18} type='Bold'>{`${item.title}${item.firstname} ${item.lastname}`}</Text>}
                description={<Text size={16}>{t('family.relationship')}: <RelativeName>{relativeTypeName(item.relativeTypeId)}</RelativeName></Text>}
              />
            </List.Item>
          )}
        />
      </ContentLayout>
      <CreateButton onClick={() => handleOpenModal('CREATE')}>{t('family.add')}</CreateButton>
    </Layout>
  )
}

export default Relative