import React, { useCallback, useEffect, useState } from 'react'
import AzureAuthenticationContext from '../../services/authAzure/azure-authentication-context'
import { AccountInfo } from '@azure/msal-browser'
import { UserLoginParamAD } from '../../services/auth/auth-types'
import { useLoginAD } from '../../services/auth/auth-query'
import { useVisibility } from '../../utils/custom-hook'
import Modal from '../../components/common/Modal'
import Button from '../../components/common/Button'
import Text from '../../components/common/Text'

const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const isIE = msie > 0 || msie11 > 0

const LoginADAuto = () => {
  // Azure client context
  const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext()
  // const [user, setUser] = React.useState<AccountInfo>()
  const [errorMessage, setErrorMessage] = useState('')
  const [modalVisible, modalAction] = useVisibility()

  const { mutate: login } = useLoginAD({
    onError: (e) => {
      if (e.message) {
        setErrorMessage(e.message)
        modalAction.show()
      }
    },
  })

  const onSubmit = useCallback(
    (token, user) => {
      const dataNew: UserLoginParamAD = {
        token: token,
      }
      login(dataNew)
    },
    [login],
  )

  // Azure Login
  const logIn = (method: string): any => {
    const typeName = 'loginPopup'
    const logInType = isIE ? 'loginRedirect' : typeName
    authenticationModule.login(logInType, returnedAccountInfo)
  }

  // Azure Logout
  // const logOut = (): any => {
  //   if (user) {
  //     onSubmit(undefined)
  //     authenticationModule.logout(user)
  //   }
  // }

  useEffect(() => {
    logIn('loginPopup')
  }, [])

  const returnedAccountInfo = (user: AccountInfo, username: string, token: string) => {
    onSubmit(token, username)
  }
  return (
    <div>
      <Modal visible={modalVisible} onCancel={modalAction.hide} hideFooter>
        <div
          style={{
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Text>{errorMessage}</Text>
          <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={modalAction.hide}>
            Cancel
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default LoginADAuto