import React from 'react'
import styled from '@emotion/styled'
import Text from '../../components/common/Text'
import List from './List'

const Layout = styled.div`
  > * {
    margin-top: 24px;
  }
  margin-bottom: 40px;
  text-align: center;
`


const HealthTips = () => {
  return (
    <Layout>
      <Text className="title-font" size={28} type="Bold">
        Health Tips
      </Text>
      <List />
    </Layout>
  )
}

export default HealthTips
