import { Configuration, LogLevel } from '@azure/msal-browser'
import config from '../../config'

const AzureADClientId: any = config.azureActiveDirectoryAppClientId
const AzureADAuthority: any = config.azureActiveDirectoryAppAuthority
const AzureADRedirectUri: any = config.azureActiveDirectoryAppRedirectUri

export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AzureADClientId,
    authority: AzureADAuthority,
    knownAuthorities: [],
    cloudDiscoveryMetadata: '',
    redirectUri: AzureADRedirectUri,
    postLogoutRedirectUri: AzureADRedirectUri,
    navigateToLoginRequestUrl: true,
    // clientCapabilities: ['CP1'],
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}
