import { CSSProperties, useMemo } from 'react'
import styled from '@emotion/styled'
import Image from './Image'
import { Carousel } from 'antd'
import { CarouselProps } from 'antd/lib/carousel'
import color from '../../constant/colors'
import { mobile } from '../../utils/responsive-helper'
import config from '../../config'

const CustomStyleCarousel = styled(Carousel) <{
  dotColor?: string
}>`
  background-color: #000000;

  .slick-dots li.slick-active button {
    background-color: ${({ dotColor }) => dotColor || color.PRIMARY};
    width: 80px;
    height: 16px;
    border-radius: 8px;
    opacity: 1;
  }
  .slick-dots li button {
    background-color: ${color.WHITE};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    opacity: 1;
  }

  .slick-dots li.slick-active {
    width: 85px;
  }

  ${mobile} {
    .slick-dots li.slick-active button {
      width: 36px;
      height: 7px;
    }
    .slick-dots li button {
      width: 7px;
      height: 7px;
    }

    .slick-dots li.slick-active {
      width: 36px;
    }

    .slick-dots li {
      width: 5px;
    }
  }
`

type ImageSliderProps = {
  dotColor?: string
  imageHeight?: number
  images?: ImageProps[]
  style?: CSSProperties
  status?: string
} & Pick<CarouselProps, 'autoplay' | 'dotPosition' | 'dots' | 'easing' | 'effect'>

export type ImageProps = {
  imagePath?: string
  title?: string
  status?: string
  onClick?: () => void
}

const ImageShopSlider = (props: ImageSliderProps) => {
  const { dotColor, imageHeight, images, style, status, ...resProps } = props

  const content = useMemo(() => {
    const imageEmpty: ImageProps[] = [
      {
        imagePath: '',
        title: '',
      },
      {
        imagePath: '',
        title: ' ',
      },
    ]
    const isHaveImage = images?.length !== 0
    const imageContent = isHaveImage ? images : imageEmpty
    return imageContent?.map((image: ImageProps, index: number) => {
      const { imagePath = '', title, ...restProp } = image

      return (
        <Image
          key={index}
          className="image-slider"
          src={imagePath ? imagePath : `${config.apiHost}${imagePath}`}
          title={title}
          alt={title}
          height={imageHeight}
          style={{ objectFit: 'contain' }}
          {...restProp}
        />
      )
    })
  }, [images, status])

  return (
    <CustomStyleCarousel
      dotColor={dotColor}
      className="image-slider"
      style={style}
      effect={'scrollx'}
      {...resProps}
    >
      {content}
    </CustomStyleCarousel>
  )
}

export default ImageShopSlider