import styled from '@emotion/styled'
import color from '../../../constant/colors'
import { useRouter } from '../../../utils/helper'
import Menu from './Menu'
import Noti from './Noti'
import * as paths from '../../../constant/path'
import Profile from './Profile'
import iconLogo from '../../../assets/images/base/icon-banpu.png'
import HambergerMenu from './HambergerMenu'
import ShopMenu from './ShopMenu'
import { useScreen, mobile } from '../../../utils/responsive-helper'
import { useGetNotificationCount } from '../../../services/notification/notification-query'
import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import { useGetEnrollmentPeriod } from '../../../services/enrollment/enrollment-query'

const Layout = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${color.WHITE};
  height: 80px;
  z-index: 100;
  margin-bottom: 56px;
  ${mobile} {
    height: 90px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 1172px;
  padding: 0 16px;
`

const MenuGroup = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const Logo = styled.img`
  ${mobile} {
    width: 48px;
  }
`

const AppHeader = () => {
  const { pathname } = useRouter()
  const { isMobile } = useScreen()
  const { data: notiCount = 0 } = useGetNotificationCount()

  const isHighlightShop = useMemo(() => {
    const inShopBrands = pathname.includes(paths.shopBrand().split('/')[1])
    const inShopCategory = pathname.includes(paths.shopCategory().split('/')[1])
    const inEnrollment = pathname.includes(paths.enrollment().split('/')[1])
    return inShopBrands || inShopCategory || inEnrollment
  }, [pathname])

  // const isShopSection = useMemo(() => {
  //   return (
  //     pathname.startsWith(paths.coupon()) ||
  //     pathname.startsWith(paths.order()) ||
  //     pathname.startsWith(paths.product()) ||
  //     pathname.startsWith(paths.promotion()) ||
  //     pathname.startsWith(paths.promotionCategory()) ||
  //     pathname.startsWith(paths.shop())
  //   )
  // }, [pathname])
  const isShopSection = false
  const { data: period } = useGetEnrollmentPeriod()
  const { benefitYearId = 3 } = period || {}

  const menuList = [
    // { title: 'Home', path: paths.home(), isActive: pathname.startsWith(paths.home()) },
    // { title: 'News', path: paths.news(), isActive: pathname.startsWith(paths.news()) },
    // { title: 'Play', path: paths.play(), isActive: pathname.startsWith(paths.play()) },
    // {
    //   title: 'Well-Being',
    //   path: paths.wellBeing(),
    //   isActive: pathname.startsWith(paths.wellBeing()),
    // },
    // {
    //   title: 'Insurance',
    //   path: paths.enrollment(),
    //   isActive: pathname.startsWith(
    //     paths.enrollment(),
    //   ),
    // },
    // {
    //   title: 'Health Checkup',
    //   path: paths.healthCheckup(),
    //   isActive: pathname.startsWith(
    //     paths.healthCheckup(),
    //   ),
    // },
    {
      title: 'e-Claims',
      path: paths.eClaim(),
      isActive: pathname === paths.eClaim(),
    },
    // { title: 'Marketplace', path: paths.shop(), isActive: pathname.startsWith(paths.shop()) },
    // {
    //   title: 'Promotion',
    //   path: paths.promotion(),
    //   isActive: pathname.startsWith(paths.promotion()),
    // },
    // { title: 'Shop', path: paths.shop(), isActive: pathname.startsWith(paths.shop()) },
    // { title: 'Order', path: paths.order(), isActive: pathname.startsWith(paths.order()) },
  ]

  return (
    <>
      {isShopSection ? (
        <ShopMenu />
      ) : (
        <Layout>
          <Header>
            {/* <Link to={paths.home()}>
              <Logo
                src={iconLogo}
                style={{
                  height: '79px',
                  width: isMobile === true ? '100px' : '',
                }}
              />
            </Link> */}
            <MenuGroup>
              {!isMobile ? (
                <>
                  {menuList.map((menu, index) => (
                    <Menu key={index} path={menu.path} isActive={menu.isActive}>
                      {menu.title}
                    </Menu>
                  ))}
                  <Link to={paths.notification()}>
                    <Noti count={notiCount} />
                  </Link>
                  <Profile />
                </>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Link to={paths.notification()}>
                    <Noti count={notiCount} />
                  </Link>
                  <HambergerMenu count={notiCount} menuList={menuList} />
                </div>
              )}
            </MenuGroup>
          </Header>
        </Layout>
      )}
    </>
  )
}

export default AppHeader
