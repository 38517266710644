
import { AccountInfo } from '@azure/msal-browser'
import styled from '@emotion/styled'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import ImageBackground from '../../assets/images/base/free-i-phone-xr-fill-1-mask.svg'
import ImageForeground from '../../assets/images/base/undraw-social-sharing-ibwq.svg'
import Button from '../../components/common/Button'
import Modal from '../../components/common/Modal'
import Text from '../../components/common/Text'
import config from '../../config'
import color from '../../constant/colors'
import { useLogin, useLoginAD } from '../../services/auth/auth-query'
import { UserLoginParamAD, UserLoginParam } from '../../services/auth/auth-types'
import { useVisibility } from '../../utils/custom-hook'
import { setToken } from '../../utils/helper'
import { iphoneXLandscape, mobile, tablet, useScreen } from '../../utils/responsive-helper'
import LoginAD from './LoginAD'
import LoginForm from './LoginForm'

const ssoLogin: any = config.ssoLogin

const Layout = styled.div`
  display: flex;
  height: 100vh;
  > div {
    width: 50%;
  }

  ${mobile}, ${iphoneXLandscape} {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`
const FormLayout = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mobile}, ${iphoneXLandscape} {
    margin-top: 102px;
  }
`
const ImageLayout = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(${ImageBackground});
  background-size: cover;
  ${mobile}, ${iphoneXLandscape} {
    background: unset;
    margin: 48px 0 102px;
  }
  ${tablet} {
    > .text {
      zoom: 0.8;
    }
  }
`
const Image = styled.img`
  width: 100%;
  max-width: 574px;
  margin-bottom: 48px;
`
const ButtonHide = styled.button`
  cursor: pointer;
  height: 10px;
  border: none;
  background: none;
`
// const Logo = styled.img`
//   width: calc(100% - 22px);
// `

const initialValues = {
  username: 'admin@beliveasia.com',
  password: '123456',
}

const Login = (props: any) => {
  const { search } = useLocation()
  const { isMobile, isIphoneXLandscape } = useScreen()
  const [modalVisible, modalAction] = useVisibility()
  const [modalLoginTestVisible, modalLoginTestAction] = useVisibility()
  const [errorStatus, setErrorStatus] = useState<string | number | undefined>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [currentUser, setCurrentUser] = useState<AccountInfo>()
  // const { data } = useLoginType()
  const { t } = useTranslation()

  const { mutate: login } = useLoginAD({
    onError: (e) => {
      if (e.error.response?.status) {
        setErrorStatus(e.error.response.status)
      }
      setErrorMessage(e.message)
      modalAction.show()
    },
  })

  const query = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    const hash = query.get("hash")

    if (hash) {
      setToken(hash)
      window.location.reload();
    }

  }, [query])

  // env http://52.237.99.252:8080
  const onSubmitAD = useCallback(
    (token, user) => {
      const dataNew: UserLoginParamAD = {
        token: token,
      }
      login(dataNew)
    },
    [login],
  )

  const { mutate: loginDebug, isLoading } = useLogin({
    onError: (e) => {
      if (e.message) {
        setErrorMessage(e.message)
        modalAction.show()
      }
    },
  })

  return (
    <Layout>
      <Modal visible={modalVisible} onCancel={modalAction.hide} hideFooter>
        <div
          style={{
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {errorStatus && <Text>{errorStatus}</Text>}
          <Text>{errorMessage}</Text>
          <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={modalAction.hide}>
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal visible={modalLoginTestVisible} onCancel={modalLoginTestAction.hide} hideFooter>
        <div
          style={{
            marginTop: 50,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LoginForm
            onSubmit={(form: UserLoginParam) => {
              const { username, password } = form
              loginDebug({
                username,
                password,
              })
            }}
            loading={isLoading}
            // initialValues={['development'].includes(config.appMode) ? initialValues : undefined}
            error={{ password: 'error' }}
          />
        </div>
      </Modal>
      <FormLayout>
        <LoginAD loading={true} onSubmit={onSubmitAD} />
        {/* <Button style={{ width: 300, margin: '50px 0 0 0' }} onClick={() => window.open(ssoLogin, "_self")}>
          {t('login.button.submitDentsu')}
        </Button> */}
        {/* <LoginForm
          onSubmit={(form: UserLoginParam) => {
            const { username, password } = form
            loginDebug({
              username,
              password,
            })
          }}
          loading={isLoading}
          initialValues={['development'].includes(config.appMode) ? initialValues : undefined}
          error={{ password: 'error' }}
        /> */}


        {/* {['development'].includes(config.appMode) && <DebugLogin />} */}
      </FormLayout>
      <ImageLayout>
        <Image src={ImageForeground} />
        {!isMobile && !isIphoneXLandscape && (
          <>
            <Text className="text" type="Bold" size={56} color={color.SECONDARY}>
              {t('login.content.flexBenefits')}
            </Text>
            {/* <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
              {t('login.content.content1')}
            </Text> */}
            <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
              {t('login.content.content2')}
            </Text>
            <Text className="text" type="Bold" size={26} color={color.FONT_BLACK}>
              {t('login.content.content3')}
            </Text>
            {['development'].includes(config.appMode) && <ButtonHide onClick={modalLoginTestAction.show}>{' '}</ButtonHide>}
          </>
        )}
      </ImageLayout>
    </Layout>
  )
}

export default Login
