import { useGetProductCategoryList } from '../../services/shop/shop-query'
import ProductList from './ProductList'
import Loading from '../../components/common/Loading'

const ProductCategoryList = () => {
  const { data, isLoading } = useGetProductCategoryList()

  return (
    <Loading loading={isLoading}>
      {data?.content.map((content: any, index: number) => {
        const { id, name } = content

        return <ProductList key={index} categoryId={id} title={name} />
      })}
    </Loading>
  )
}

export default ProductCategoryList
