import { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'

import {
  useGetHealth
} from '../../services/well-being/well-being-query'
import TipsCard from './TipsCard'
import Loading from '../../components/common/Loading'
import Button from '../../components/common/Button'
import * as paths from '../../constant/path'
import { useHistory } from 'react-router'

const Layout = styled.div`
  display: flex;
  flex-direction: column;
`

const LoadingStyle = styled.div`
  display: flex;
  justify-content: center;
`

const CustomerStyleButton = styled(Button)`
  margin-bottom: 10px;
`

const List = () => {
  const history = useHistory()
  const { data, isLoading } = useGetHealth
    ({
      size: 20
    })

  const onClickCard = useCallback(
    async (health_tip_id: number) => {
      try {
        history.push(paths.healthTipsDetails({ routeParam: { health_tip_id } }))
      } catch { }
    },
    [],
  )

  const contents = useMemo(() => {
    return data?.pages.map(({ data: contents }) => {
      return contents.map((content: any, index: number) => {
        const { healthTipId, thumbnailImage, name, title, description, createDate } = content
        return (
          <TipsCard
            id={healthTipId}
            key={index}
            imagePath={thumbnailImage}
            title={title}
            description={description}
            name={name}
            date={createDate}
            onCardClick={() => onClickCard(healthTipId)} />
        )
      })
    })
  }, [data])

  return (
    <Loading style={{ height: 100 }} loading={isLoading}>
      <Layout>{contents}</Layout>
    </Loading>
  )
}

export default List
