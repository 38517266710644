export type EClaimListItem = {
  claimId: number
  claimName: string
  claimIcon: string
  maxClaimablePrice: number
  fixExchangeCoin: string
  availableAmount: number
  claimDetail: string
  claimTypeId: number
  sumClaimAmount: number
}

export type EClaimList = Array<EClaimListItem>

export type GetEClaimByIdProps = {
  claimId: number
}
export type GetEClaimHistoryByIdProps = {
  id: number
}

export type RelativeGroup = {
  typeId: number
  typeName: string
  maxNumber: number
  description: string
  relativeList: RelativeList[]
}

export type RelativeList = {
  id: number
  title: string
  firstname: string
  lastname: string
  birthday: string
  nationalId: number
  userId: number
  relativeTypeId: number
  createdDate: number
  createdBy: string
  updatedDate: number
  updatedBy: string
  enable: boolean
}
export type GetRelativeResponse = RelativeGroup[]

export type RelativeScg = {
  typeId: number
  typeName: string
  id: number
  title: string
  firstname: string
  lastname: string
}
export type RelativeScgResponse = RelativeScg[]

export type ECliamHistoryListResponse = EClaimHistory[]

export enum EClaimStatus {
  PROCESSING = 'Processing',
  APPROVED = 'Approved and waiting for document submission',
  REJECTED = 'Rejected',
  USER_CANCELLED = 'User cancelled',
  PAYMENT_CONFIRMED = 'Payment confirmed',
}

export type EClaimHistory = {
  id: number
  claimIcon?: string
  userId: string
  userName: string
  claimId: number
  exchangeCoin: number
  claimName: string
  createdDate: string
  receiptFile: string
  optionalFileUrl: string
  accept: boolean
  remark: string
  userRemark: string
  status: EClaimStatus
  acceptBy: string
  empId: string
  firstName: string
  lastName: string
  fullName: string
  refundDate: string
  allpayStatus: string
  allpayUpdateDate: string
  allpayRemark: string
  allpayReason: string
  updatedDate: string
}

export type EClaimItem = {
  id?: number
  docNo?: string
  claimId: number
  claimTypeId: number
  claimName: string
  claimIcon: string
  availableAmount: number
  fixExchangeCoin: string
  claimPrice: number
  claimDetail: string
  allowedAmount: number
  limit: number
  relativeTypeId: number
  allpayRef?: string
  createdDate?: number
  receiptFile?: string
  optionalFileUrl?: string
  relativeId?: string
  remark?: string
  userRemark?: string
  exchangeCoin?: number
  userClaimPrice?: number
  allpayStatus?: string
  accept?: boolean
  tax?: boolean
  allpayReason?: string
  allpayRemark?: string
  status?: EClaimStatus
  updatedDate?: any
  approveDate?: any
  paymentDate?: any
}
export type CreateEclaimUser = {
  claimId: number
  exchangeCoin: number
  userClaimPrice: number
  claimName: string
  receiptFile?: string
  optionalFileUrl?: string
  relativeId?: string
  userRemark?: string
  allpayRef?: string
  id?: string | number
}
export type UploadFile = string
export type UploadReceiptImageResponse = string

export type EClaimFormParams = {
  id?: number
  name: string
  exchangeCoin: number
  userClaimPrice: number
  claimDate: moment.Moment
  paymentDate: moment.Moment
  optionalFileUrl: string
  receiptFile: string
  relativeId: string
  userRemark: string
}

export type EClaimEnllomentItem = {
  totalSelectedCoin?: number
  preSelectedClaimWrapperList: PreSelectedClaimWrapperList[]
  claimTypeName: string
  claimTypeId: string | number
}

export type PreSelectedClaimWrapperList = {
  maxClaimablePrice: number
  fixExchangeCoin: boolean
  detail: string
  preSelectedAmount: number
  claimName: string
  claimId: number
}

export type EClaimEnllomentItemList = Array<EClaimEnllomentItem>

export type CreateUserClaimEnrollment = {
  claimTypeId: string
  benefitYearId: string
  usersPreSelectedClaimsDTOList: UsersPreSelectedClaimsDTOList[]
}

export type UsersPreSelectedClaimsDTOList = {
  preSelectedAmount: number
  claimId: string
}

export type CreateUserClaimEnrollments = Array<CreateUserClaimEnrollment>

export type EClaimListResponse = EClaimListGroup[]

export type EClaimListGroup = {
  claimTypeGroupId: number
  claimTypeGroupName: string
  blcpClaimTypeWrapperList: EClaimType[]
}

export type EClaimType = {
  claimTypeId: number
  claimName: string
  claimTypeGroupId: number
  claimTypeGroupName: string
  blcpClaimWrapperList: EClaimListNew[]
}

export type EClaimListNew = {
  claimId: number
  claimName: string
  claimIcon: string
  maxClaimablePrice: number
  fixExchangeCoin: boolean
  availableAmount: number
  claimDetail: string
  claimTypeId: number
  sumClaimAmount: number
  claimTypeGroupId: number
  claimTypeName: string
  claimTypeGroupName: string
  isTax: boolean
}
