import React from 'react'
import styled from '@emotion/styled'

import Category from './Category'
import Header from './Header'

import { mobile } from '../../utils/responsive-helper'
import Point from '../../components/common/Point'

const Layout = styled.div`
  padding-bottom: 40px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.1);
  > * {
    margin-top: 24px;
  }
  
  ${mobile} {
    padding: 0px 16px 40px;
  }
`
const WidthContent = styled.div`
  max-width: 752px;
  margin: 0 auto;
`
const PointLayout = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`


const News = () => {
  return (
    <Layout>
      <WidthContent>
        <PointLayout>
          <Point />
        </PointLayout>
        <Header />
        <Category />
      </WidthContent>
    </Layout>
  )
}

export default News
