import React, { useCallback, useMemo, useState } from 'react'
import IconPhone from '../../assets/images/base/icon-phone.svg'
import IconHome from '../../assets/images/base/icon-home.svg'
import IconMail from '../../assets/images/base/icon-mail.svg'
import IconEdit from '../../assets/images/base/icon-edit.svg'
import Text from '../../components/common/Text'
import styled from '@emotion/styled'
import color from '../../constant/colors'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useGetUser, useGetListUserAdress } from '../../services/user/user-query'
import { useTranslation } from 'react-i18next'
import ProfileCard from '../../components/ProfileCard'
import { mobile, tablet, useScreen } from '../../utils/responsive-helper'
import Card from '../../components/common/Card'
import Button from '../../components/common/Button'
import Modal from '../../components/common/Modal'
import { useVisibility } from '../../utils/custom-hook'
import Image from '../../components/common/Image'
import EditContactForm from './EditContactForm'
import EditAddressForm from './EditAddressForm'
import { useEditUser, useEditAddressUser, useCreateAddressUser } from '../../services/user/user-query'
import { EditUserFormValues } from '../../services/user/user-typed'
// import FamilyWelfare from './FamilyWelfare'

const Layout = styled.div`
  display: flex;
  flex-flow: row;
  padding-bottom: 40px;
  margin-top: 24px;

  ${tablet} {
    flex-flow: column;
    padding: unset;
  }

  ${mobile} {
    flex-flow: column;
    margin: unset;
    padding: unset;
  }

  .profile-card {
    align-self: flex-start;
    margin-right: 24px;
    ${tablet} {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`
const BreadcrumbLayout = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;

  ${mobile} {
    margin: 24px 16px;
  }
`
const ContentLayout = styled.div`
  flex: 1;
  flex-flow: column;
  .contact-information {
    margin-bottom: 24px;
    > div:nth-of-type(1) {
      margin-bottom: 24px;
    }

    ${mobile} {
      .contact-row-1 {
        flex-flow: column;
        > div {
          :nth-of-type(1) {
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .family-welfare {
    margin-bottom: 16px;
    > div:nth-of-type(1) {
      margin-bottom: 24px;
    }
  }
`
const ContactContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .phoneNumber {
    flex: 50%;
    margin-bottom: 16px;
  }
  .email {
    flex: 50%;
    margin-bottom: 16px;
  }
  .address {
    flex: 100%;
    margin-bottom: 32px;
  }

  .address-content {
    flex: 100%;
    margin-bottom: 32px;
    margin-top: 32px;
  }

  .phoneNumber,
  .email,
  .address {
    align-items: baseline;
    > div {
      :nth-of-type(1) {
        white-space: nowrap;
      }
    }
  }
  ${mobile} {
    flex-flow: column;
    justify-content: flex-start;

    .address-content {
      margin-top: 16px;
    }

    .phoneNumber,
    .email,
    .address-content,
    .address {
      flex: 100%;
      margin-bottom: 16px;
      flex-flow: row wrap;
      align-items: baseline;
      > div {
        :nth-of-type(1) {
          white-space: nowrap;
        }
      }
    }
  }
`

const CardStyled = styled(Card)`
  padding: 32px;
`
const ButtonStyled = styled(Button)`
  border-color: ${color.PRIMARY};
`

const FlexRow = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  flex: 1;
  align-items: center;
`

const IconImageStyled = styled(Image)`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`
// mock data ไว้นะครับ ถ้ามีข้อมูลจาก api เอาไปเปลี่ยนด้วย
// const mockFamilyelfare = [
//   {
//     relation: 'แม่',
//     name: 'นายสมหมาย สุขสำราญ',
//     citizenId: '3987788976465',
//     age: 58,
//   },
//   {
//     relation: 'มารดา',
//     name: 'นายสมหญิง สุขสำราญ',
//     citizenId: '3987788976466',
//     age: 58,
//   },
//   {
//     relation: 'ภรรยา',
//     name: 'นายสมใจ สุขสำราญ',
//     citizenId: '3987788976467',
//     age: 58,
//   },
//   {
//     relation: 'ลูกสาว',
//     name: 'เด็กหญิงชูใจ สุขสำราญ',
//     citizenId: '3987788976468',
//     age: 58,
//   },
// ]

const Setting = () => {
  const { data: user } = useGetUser()
  const { data: address } = useGetListUserAdress()
  const { userId } = user || {}
  const { t } = useTranslation()

  const { mutate } = useEditUser(userId)
  const { mutate: editAddress } = useEditAddressUser()
  const { mutate: createAddress } = useCreateAddressUser()
  const { isMobile } = useScreen()
  const [visible, action] = useVisibility()
  const [modalAdress, setModalAddress] = useState(false)


  const onEditButtonPress = useCallback(() => {
    action.show()
  }, [action])

  const onSubmit = useCallback(
    (form: EditUserFormValues) => {
      mutate(form)
      action.hide()
    },
    [action, mutate],
  )

  const onSubmitAddress = useCallback(
    (objectForm) => {
      if (address?.length !== 0) {
        const newForm = {
          ...address?.[0], ...objectForm
        }

        editAddress(newForm)
        setModalAddress(false)
      }
      else {
        createAddress({ ...objectForm, userId })
        setModalAddress(false)
      }
    },
    [editAddress, address, createAddress, userId],
  )

  const initialAddressValues = useMemo(() => {
    if (address?.length !== 0) {
      return {
        street: address?.[0].street,
        subdistrict: address?.[0].subDistrict,
        district: address?.[0].district,
        province: address?.[0].province,
        postcode: address?.[0].postcode,
        country: address?.[0].country,
        provinceId: address?.[0].provinceId,
        districtId: address?.[0].districtId,
        subdistrictId: address?.[0].subDistrictId,
        countryCode: address?.[0].countryCode,
        provinceCode: address?.[0].provinceCode,
        amphoeCode: address?.[0].amphoeCode,
        subdistrictCode: address?.[0].subdistrictCode,
        countryId: address?.[0].countryId,
        createdDate: address?.[0].createdDate,
        createdBy: address?.[0].createdBy,
        updatedDate: address?.[0].updatedDate,
        updatedBy: address?.[0].updatedBy,
        userId
      }
    }
  }, [address, userId])

  const initialValues = useMemo(() => {
    const { phoneNumber, email } = user || {}
    return {
      phoneNumber,
      email,
    }
  }, [user])

  return (
    <>
      {/* <BreadcrumbLayout>
        <Breadcrumb />
      </BreadcrumbLayout> */}
      {!isMobile && (
        <Text size={28} type="Bold" color={color.WHITE} style={{ textAlign: 'center' }}>
          {t('setting.header')}
        </Text>
      )}
      <Layout>
        <ProfileCard size="medium" />
        <ContentLayout>
          <CardStyled className="contact-information">
            <Text size={24} type="Bold" color={color.FONT_BLACK}>
              {t('setting.contactHeader')}
            </Text>
            <ContactContent>
              <FlexRow className="phoneNumber">
                <IconImageStyled src={IconPhone} />
                <Text size={20} type="Medium" color={color.FONT_LIGHT_GRAY}>
                  {`${t('setting.phoneNumber')} :`}
                </Text>
                <Text size={20} type="Medium" color={color.FONT_BLACK} style={{ marginLeft: 5 }}>
                  {user?.phoneNumber || '-'}
                </Text>
              </FlexRow>
              <FlexRow className="email">
                <IconImageStyled src={IconMail} />
                <Text size={20} type="Medium" color={color.FONT_LIGHT_GRAY}>
                  {`${t('setting.email')} :`}
                </Text>
                <Text size={20} type="Medium" color={color.FONT_BLACK} style={{ marginLeft: 5 }}>
                  {user?.email || '-'}
                </Text>
              </FlexRow>
            </ContactContent>
            {/* <FlexRow style={{ justifyContent: 'center' }}>
              <ButtonStyled
                onClick={onEditButtonPress}
                background={color.WHITE}
                textProps={{ color: color.PRIMARY }}
              >
                <FlexRow>
                  <IconImageStyled src={IconEdit} />
                  <Text size={20} color={color.PRIMARY}>
                    {t('setting.editButton')}
                  </Text>
                </FlexRow>
              </ButtonStyled>
            </FlexRow> */}

            {/* <ContactContent>
              <FlexRow className="address-content">
                <IconImageStyled src={IconHome} />
                <Text size={20} type="Medium" color={color.FONT_LIGHT_GRAY}>
                  {`${t('setting.address')} :`}
                </Text>
                <Text size={20} type="Medium" color={color.FONT_BLACK} style={{ marginLeft: 5 }}>
                  {address?.[0] !== undefined ?
                    `${address?.[0].street} ${address?.[0].subDistrict} ${address?.[0].district} ${address?.[0].province} ${address?.[0].postcode}` : '-'}
                </Text>
              </FlexRow>
            </ContactContent> */}

            {/* <FlexRow style={{ justifyContent: 'center' }}>
              <ButtonStyled
                onClick={() => setModalAddress(true)}
                background={color.WHITE}
                textProps={{ color: color.PRIMARY }}
              >
                <FlexRow>
                  <IconImageStyled src={IconEdit} />
                  <Text size={20} color={color.PRIMARY}>
                    {t('setting.editButton')}
                  </Text>
                </FlexRow>
              </ButtonStyled>
            </FlexRow> */}
          </CardStyled>
          {/* <CardStyled className="family-welfare">
            <Text size={24} type="Bold" color={FONT_BLACK}>
              {t('setting.familyWelfareHeader')}
            </Text>
            <FamilyWelfare data={mockFamilyelfare} />
          </CardStyled> */}
          {/* <Text size={20} color={color.FONT_LIGHT_GRAY}>
            {t('setting.familyWelfareNote')}
          </Text> */}
        </ContentLayout>
      </Layout>
      <Modal
        visible={visible}
        onCancel={action.hide}
        style={{
          padding: 0,
        }}
        hideFooter
      >
        <EditContactForm onSubmit={onSubmit} initialValues={initialValues} />
      </Modal>

      <Modal
        visible={modalAdress}
        onCancel={() => setModalAddress(false)}
        style={{
          padding: 0,
        }}
        hideFooter
      >
        <EditAddressForm onSubmit={onSubmitAddress} initialValues={initialAddressValues} />
      </Modal>
    </>
  )
}

export default Setting
