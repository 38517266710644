import { CSSProperties, useCallback } from 'react'
import styled from '@emotion/styled'
import Card from './common/Card'
import Avatar from './common/Avatar'
import Text from './common/Text'
import Image from './common/Image'
import PointLabel from './common/PointLabel'
import color from '../constant/colors'
import { useGetUser, useGetUserCoin, useGetListUserAdress } from '../services/user/user-query'
import { mobile, tablet, useScreen } from '../utils/responsive-helper'
import icon from '../assets/images/icons-history-18-px.svg'
import { useTranslation } from 'react-i18next'
import { pointHistory } from '../constant/path'
import { useHistory } from 'react-router'
import { Tooltip } from 'antd'
import { css } from '@emotion/css'
import config from '../config'

const ProfileLayoutMediumCss = css`
  width: 364px;
  max-width: 364px;
  height: 404px;
  max-height: 404px;
  padding: 19px;
  grid-row-gap: 16px;
  align-self: flex-end;
  .name {
    margin-bottom: 0px;
  }
  .job {
    margin-bottom: 2px;
  }
  .point-label {
    margin-bottom: 0px;
  }
  .point-banner {
    margin-top: 0;
    margin-bottom: 10px;
  }
  ${tablet} {
    grid-template-rows: unset;
    grid-template-columns: min-content auto;
    grid-column-gap: 16px;

    width: 100%;
    max-width: unset;
    height: min-content;
    max-height: unset;
    padding: 16px;

    .name {
      margin-bottom: 0;
    }
    .job {
      margin-bottom: 8px;
    }
    .point-label {
      margin-bottom: 8px;
    }
    .point-banner {
      margin-bottom: 8px;
    }
  }

  ${mobile} {
    grid-template-rows: unset;
    grid-template-columns: min-content auto;
    grid-column-gap: 8px;

    width: 100%;
    max-width: unset;
    height: 151px;
    padding: 16px;

    .name {
      margin-bottom: 0;
    }
    .job {
      margin-bottom: 8px;
    }
    .point-banner {
      margin-bottom: 8px;
    }
  }
`

const ProfileLayoutDefault = css`
  grid-template-rows: min-content auto;
  grid-row-gap: 21px;
  width: 364px;
  height: 465px;
  padding: 24px;

  .name {
    margin-bottom: 6px;
  }
  .job {
    margin-bottom: 10px;
  }
  .point-label {
    margin-bottom: 6px;
  }
  .point-banner {
    margin-top: 0;
    margin-bottom: 14px;
  }

  ${tablet} {
    grid-row-gap: 8px;

    height: 426px;
    padding: 16px;

    .name {
      margin-bottom: 0;
    }
    .job {
      margin-bottom: 8px;
    }
    .point-banner {
      margin-bottom: 8px;
    }
  }

  ${mobile} {
    grid-template-rows: unset;
    grid-template-columns: min-content auto;
    grid-column-gap: 8px;

    width: 100%;
    height: 151px;
    padding: 16px;

    .name {
      margin-bottom: 0;
    }
    .job {
      margin-bottom: 8px;
    }
    .point-banner {
      margin-bottom: 8px;
    }
  }
`

const ProfileLayout = styled(Card)`
  display: grid;
  height: 100%;
`

const CustomeStyleAvatar = styled(Avatar)`
  margin: 0 auto;
  ${mobile} {
    margin: auto 0;
  }
`

const LayoutContentDefaultCss = css`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  ${mobile} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
`
const LayoutContentMediumCss = css`
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
`
const LayoutContent = styled.div`
  overflow: hidden;
  display: flex;
`

const NameText = styled(Text)`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${tablet} {
    text-align: start;
  }
`
const StyleText = styled(Text)`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${tablet} {
    text-align: start;
  }
`

const CustomStylePointBanner = styled(PointLabel)``

const StyleTextPointer = styled(Text)`
  cursor: pointer;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const JobLayout = styled.div`
  display: grid;
  grid-row-gap: 8px;
  background-color: #f2f2f2;
  border-radius: 4px;
  width: 100%;
  text-align: left;
  padding: 12px 16px;
  margin-top: 32px;
`

const ProfileDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  margin-top: 16px;
`

const ProfileDetailItem = styled.div`
  display: flex;
  column-gap: 12px;
`

type ProfileCardProps = {
  size?: 'default' | 'medium'
  style?: CSSProperties
  onClick?: () => void
  className?: string
}

const ProfileCard = (props: ProfileCardProps) => {
  const { t } = useTranslation()
  const { data: user } = useGetUser()
  const { data: userCoin } = useGetUserCoin()
  const { data: address } = useGetListUserAdress()
  const { onClick, size = 'default', className, ...rest } = props
  const { isMobile } = useScreen()
  const history = useHistory()

  const onPointHistoryClick = useCallback(() => {
    history.push(pointHistory())
  }, [history])

  return (
    <ProfileLayout
      className={`profile-card ${size === 'medium' ? ProfileLayoutMediumCss : ProfileLayoutDefault
        }`}
      {...rest}
    >
      <CustomeStyleAvatar
        src={`${config.apiHost}${user?.profileImagePath}`}
        isBorder={true}
        size={!isMobile ? 185 : 107}
        borderWidth={isMobile ? 12 : 7}
      />
      <LayoutContent
        className={`${size === 'medium' ? LayoutContentMediumCss : LayoutContentDefaultCss}`}
      >
        <Tooltip title={`${user?.firstName} ${user?.lastName}`}>
          <NameText className="name" size={!isMobile ? 20 : 18} type={'Bold'}>
            {`${user?.firstName} ${user?.lastName}`}
          </NameText>
        </Tooltip>
        {!isMobile && (
          <StyleText className="point-label" size={16} type={'Medium'}>
            {t('profileCard.availableMoney')}
          </StyleText>
        )}
        {/* <JobLayout>
          <StyleText size={!isMobile ? 10 : 8} type={'Medium'} color={color.FONT_BLACK}>
            {t('profileCard.titleJob')}
          </StyleText>
          <StyleText size={!isMobile ? 16 : 14} type={'Medium'} color={color.FONT_BLACK}>
            {user?.position || t('profileCard.noJob')}
          </StyleText>
        </JobLayout> */}
        <CustomStylePointBanner
          className="point-banner"
          label={userCoin || 0}
          size={!isMobile ? 'default' : 'small'}
        />
        {/* <StyleTextPointer
          className="point-history"
          size={!isMobile ? 14 : 12}
          type={'Medium'}
          onClick={onPointHistoryClick}
        >
          <Image imageName={'iconHistory'} /> {t('profileCard.moneyHistory')}
        </StyleTextPointer> */}
        {/* IdCardIcon */}
        {/* <ProfileDetailWrapper>
          <ProfileDetailItem>
            <Image imageName="idCardIcon" width={20} />
            <StyleText size={!isMobile ? 16 : 14} color={color.FONT_BLACK}>
              {user?.idCard}
            </StyleText>
          </ProfileDetailItem>
          <ProfileDetailItem>
            <Image imageName="IconPhoneNumber" width={20} />
            <StyleText size={!isMobile ? 16 : 14} color={color.FONT_BLACK}>
              {user?.phoneNumber}
            </StyleText>
          </ProfileDetailItem>
          <ProfileDetailItem>
            <Image imageName="IconMail" width={20} />
            <StyleText size={!isMobile ? 16 : 14} color={color.FONT_BLACK}>
              {user?.email}
            </StyleText>
          </ProfileDetailItem>
          <ProfileDetailItem>
            <Image imageName="IconLocation" width={20} />
            <StyleText size={!isMobile ? 16 : 14} color={color.FONT_BLACK}>
              {address?.[0] !== undefined
                ? `${address?.[0].street} ${address?.[0].subDistrict} ${address?.[0].district} ${address?.[0].province} ${address?.[0].postcode}`
                : '-'}
            </StyleText>
          </ProfileDetailItem>
        </ProfileDetailWrapper> */}
      </LayoutContent>
    </ProfileLayout>
  )
}

export default ProfileCard
